import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'components/common/layout';
import { setLanguage } from 'utils/language';
import { checkLocalStorageForUser } from 'store/reducers/authReducer';
import { checkAuthAction } from 'store/actions/auth';
import { renderedRoutes } from 'routers';

import { useAppDispatch, useAppSelector } from 'store';
import Loader from 'components/common/loader';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CertificateDocumentPage } from 'pages';
import useUser from 'hooks/useUser';

const App = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const { user } = useUser()

  useEffect(() => {
    const user = localStorage.getItem('user-wt');
    if (user) {
      dispatch(checkAuthAction())
      dispatch(checkLocalStorageForUser())
    }
    setLanguage();
    setLoading(false)
  }, [dispatch]);

  return (
    <Layout>
      <ToastContainer
        position="top-left"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
      {
        !loading ?
          <Routes>
            {renderedRoutes(user?.is_superuser)}
            
            <Route path="/certificate" element={<CertificateDocumentPage/>}/>
          </Routes>
          :
          <Loader loading={loading} />

      }


    </Layout>
  );
};

export default App;