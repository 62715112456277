import React, { useState } from 'react';
import { MenuItem, Avatar, Box, Divider, IconButton, Tooltip, Typography, ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import Logout from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from 'store';
import { logoutAction } from 'store/actions/auth';
import { useTranslation } from 'react-i18next';

const UserAvatar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {user} = useAppSelector((state) => state.store.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    handleClose();
  };

  const menuItems = [
    { label: t('logout'), onClick: handleLogout, icon: <Logout fontSize="small" /> },
  ];

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 40, height: 40 }}>{user?.username[0].toLocaleUpperCase()}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <Typography variant="body2" sx={{p:1,fontSize:'1rem', fontWeight:'bold'}}>{item.label}</Typography>
          </MenuItem>
        ))}
      
      </Menu>
    </React.Fragment>
  );
};

export default UserAvatar;