import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/index';
import { CreateDocument, UpdateDocument, Document as DocumentType } from 'types/document';
import DocumentForm from 'components/document/documentForm';
import FormLoadingSkeleton from 'components/document/loadingForm';
import RecentDocumentTable from './recentDocument';
import {
    getDocumentPaginationAction,
    createDocumentAction,
    deleteDocumentAction,
    updateDocumentAction
} from 'store/actions/document';
import { getAllCustomerAction } from 'store/actions/customer';
import { getAllDriverAction } from 'store/actions/driver';
import { getAllMaterialAction } from 'store/actions/material';
import { getAllVehicleAction } from 'store/actions/vehicle';
import { getAllTrailerAction } from 'store/actions/trailer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IDashboardViewProps { }

export default function DashboardView(props: IDashboardViewProps) {
    const { t } = useTranslation();
    const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const dispatch = useAppDispatch();

    const { driversIsLoading } = useAppSelector((state) => state.store.driver);
    const { vehiclesIsLoading } = useAppSelector((state) => state.store.vehicle);
    const { materialsIsLoading } = useAppSelector((state) => state.store.material);
    const { customersIsLoading } = useAppSelector((state) => state.store.customer);
    const { trailersIsLoading } = useAppSelector((state) => state.store.trailer);

    const { documentRequest, documentsIsLoading, latestDocuments, totalDocuments } = useAppSelector(state => state.store.document);

    const onCreate = (data: CreateDocument) => {
        dispatch(createDocumentAction(data)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setSelectedDocument(resp.payload.data as DocumentType)
                toast.success(t('documentCreatedSuccessfully'));
            }
        });
    }

    const onUpdate = (data: UpdateDocument, close: boolean = false) => {
        dispatch(updateDocumentAction(data)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                if (close) {
                    setSelectedDocument(null)
                }else{
                    setSelectedDocument(resp.payload.data as DocumentType)
                    toast.success(t('documentUpdatedSuccessfully'));
                }
                
            }
        });
    }

    const onDelete = (id: string) => {
        dispatch(deleteDocumentAction(id)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setSelectedDocument(null)
                toast.success(t('Document deleted successfully'));
            }
        })
    }

    const handleSelectDocument = (Document: DocumentType | null) => {
        setSelectedDocument(Document);
    }

    const getData = (parms?:any) => {
        dispatch(getDocumentPaginationAction(parms))
    }

    useEffect(() => {
        dispatch(getAllCustomerAction())
        dispatch(getAllDriverAction())
        dispatch(getAllVehicleAction())
        dispatch(getAllTrailerAction())
        dispatch(getAllMaterialAction())
    }, [dispatch])

    
    return (
        <Stack
            width={'100%'}
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            alignContent={'center'}>
            {
                driversIsLoading || vehiclesIsLoading || materialsIsLoading || customersIsLoading || trailersIsLoading ?
                    <FormLoadingSkeleton />
                    :
                    <>
                    <DocumentForm
                        screen='create'
                        onCreate={onCreate}
                        requesting={documentRequest}
                        selectedDocument={selectedDocument}
                        handleSelectDocument={handleSelectDocument}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                    />

                    <Box sx={{ width: '70%' }}>
                        <RecentDocumentTable
                            selectedDocument={selectedDocument}
                            handleSelectDocument={handleSelectDocument}
                            getData={getData}
                        />
                    </Box>
                </>
            }

        </Stack>
    );
}
