import React from 'react';
import { Document } from 'types/document';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface PreviewDocumentViewProps {
    document: Document;
}

const removeSeconds = (time: string) => {
    return time ? time.slice(0, 5) : '';
};

const timeExceedsFiftyMinutes = (exitTime: string, date: string) => {
    const now = dayjs();
    const exit = dayjs(`${date} ${exitTime}`, 'DD/MM/YYYY HH:mm');
    return now.diff(exit, 'minute') > 50;
};

const PreviewDocumentView: React.FC<PreviewDocumentViewProps> = ({ document }) => {
    const entryTime = removeSeconds(document?.entry_time ?? '');
    const exitTime = removeSeconds(document?.exit_time ?? '');
    const documentDate = document?.date ? dayjs(document.date).format('DD/MM/YYYY') : '';
    const showAlert = document?.exit_time && timeExceedsFiftyMinutes(exitTime, documentDate.toString());

    return (
        <Box
            id="printableArea"
            sx={{
                boxSizing: 'border-box',
                position: 'relative',
                backgroundColor: 'transparent',
                color: 'black',
                borderRadius: '2px',
                padding: { xs: '1rem', sm: '1.5rem' },
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            }}
        >
            {/* Company Name and Subtitle */}
            <Box sx={{ textAlign: 'left', marginBottom: '1rem' }}>
                <Typography variant="h2" sx={{ fontWeight: 'bold' }}>ב.א.י.ו.</Typography>
                <Typography variant="subtitle1">למסחר שיווק חומרי מחצבה ותשתיות בע"מ</Typography>
            </Box>

            {/* Badges */}
            <Typography variant="h6" id="originalBadge" sx={{ position: 'absolute', top: '10px', left: '10px', color: 'red', fontWeight: 'bold', padding: '5px', borderRadius: '5px', display: 'none' }}>
                מקורי
            </Typography>
            <Typography variant="h6" id="copyBadge" sx={{ position: 'absolute', top: '10px', right: '10px', color: 'red', fontWeight: 'bold', padding: '5px', borderRadius: '5px', display: 'none' }}>
                העתק
            </Typography>

            {
                !document?.status ? (
                    <Typography variant="h6" sx={{ position: 'absolute', top: '10px', right: '10px', color: 'red', fontWeight: 'bold', padding: '5px', borderRadius: '5px' }}>
                        מבוטל
                    </Typography>
                ) :
                    null
            }

            <Box sx={{ textAlign: 'center', borderBottom: '1px solid rgba(0,0,0,0.22)', paddingBottom: '0.5cm' }}>
                {showAlert && (
                    <Typography variant="h5" sx={{ color: 'red', marginY: '0.5rem' }}>
                        עברו יותר מ- 50 דקות על הנפקת התעודה הזו
                    </Typography>
                )}
                <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>תעודת שקילה מס:</Typography> {document?.document_id ?? ''}
            </Box>

            {/* First Section */}
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,0.22)', paddingBottom: '0.5cm' }}>
                <Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>מספר רכב: </Typography>
                        <Typography component="span">{document?.vehicle?.vehicle_number ?? ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>ספק/לקוח: </Typography>
                        <Typography component="span">{document?.customer?.name ?? ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>סוג חומר: </Typography>
                        <Typography component="span">{document?.material?.name ?? ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>אסמכתא: </Typography>
                        <Typography component="span">{''}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>שם נהג: </Typography>
                        <Typography component="span">{document?.driver?.name ?? ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}> מוביל: </Typography>
                        <Typography component="span">{document?.trailer?.trailer_number}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>יעד/מקור: </Typography>
                        <Typography component="span">{document?.go_to ?? ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>הערות: </Typography>
                        <Typography component="span">{document?.comment ?? ''}</Typography>
                    </Box>
                </Box>
            </Box>

            {/* Second Section */}
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', padding: '0.5cm 0' }}>
                <Box>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>ברוטו: </Typography>
                    <Typography component="span">{Number(document?.weight_after) ?? ''} ק"ג</Typography>
                </Box>
                <Box>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>טרה: </Typography>
                    <Typography component="span">{Number(document?.weight_before) ?? ''} ק"ג</Typography>
                </Box>
                <Box>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>נטו: </Typography>
                    <Typography component="span">{document?.weight_after && document?.weight_before ? Number(document.weight_after) - Number(document.weight_before) : ''} ק"ג</Typography>
                </Box>
            </Box>

            {/* Third Section */}
            <Box sx={{ borderTop: '1px solid rgba(0,0,0,0.22)', padding: '1rem' }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,0.22)', padding: '0.5cm 0' }}>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>תאריך: </Typography>
                        <Typography component="span">{document?.date ? dayjs(document.date).format('DD/MM/YYYY') : ''}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>שעת כניסה: </Typography>
                        <Typography component="span">{entryTime}</Typography>
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>שעת יציאה: </Typography>
                        <Typography component="span">{exitTime}</Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: '1rem' }}>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>תאריך הדפסה: </Typography>
                    <Typography component="span">{dayjs().format('HH:mm DD/MM/YYYY ')}</Typography>
                </Box>
            </Box>

            {/* Fifth Section */}
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', padding: '0.5cm 0' }}>
                <Box>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>כתובת לקוח: </Typography>
                    <Typography component="span">{document?.customer?.address ?? ''}</Typography>
                </Box>
                <Box>
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>טלפון לקוח: </Typography>
                    <Typography component="span">{document?.customer?.phone ?? ''}</Typography>
                </Box>

                <Box sx={{ textAlign: { xs: 'center', sm: 'left' }, marginTop: { xs: '1rem', sm: '0' } }}>
                    <img src={document?.qr_code ?? ''} width={50} height={50} alt="QR Code" />
                    <Typography>לאימות התעודה-נא לסרוק את QR</Typography>
                </Box>
            </Box>

            <Box sx={{ textAlign: 'center', fontSize: '0.8rem' }}>
                הופק ע"י קרט נט מערכות- טל: 0508524667
            </Box>
        </Box>
    );
};

export default PreviewDocumentView;
