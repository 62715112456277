import * as React from 'react';
import { Box } from '@mui/material';
import CustomerView from 'components/customer';

export interface ICustomerPageProps {
}

export default function CustomerPage (props: ICustomerPageProps) {
  return (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      > 
        <CustomerView />
    </Box>
  );
}
