import { createAsyncThunk } from '@reduxjs/toolkit';
import { authActionType } from 'types/auth';
import { 
    checkAuthService, 
    loginService, 
    logoutService } from 'services/auth'

export const checkAuthAction = createAsyncThunk(
    'auth/checkAuth',
    async (_, thunkAPI) => {
        try {
            return await checkAuthService();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const loginAction = createAsyncThunk(
    'auth/login',
    async (data: authActionType, thunkAPI) => {
        try {
            return await loginService(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const logoutAction = createAsyncThunk(
    'auth/logout',
    async (_, thunkAPI) => {
        try {
            return await logoutService();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);