import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import { 
    getAllVehicleAction,
    createVehicleAction,
    updateVehicleAction,
    deleteVehicleAction
} from 'store/actions/vehicle';
import { getAllDriverAction } from 'store/actions/driver';
import { getAllTrailerAction } from 'store/actions/trailer';
import VehicleTable from './vehicleTable';
import { CreateVehicle, UpdateVehicle, Vehicle as VehicleType } from 'types/vehicle';
import NewVehicleForm from './newVehicleDialog';
import DeleteVehicleDialog from './deleteVehicleDialog';
import UpdateVehicleDialog from './updateVehicleDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IVehicleProps {}

export default function VehicleView(props: IVehicleProps) {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [selectedVehicle, setSelectedVehicle] = React.useState<VehicleType | null>(null);
    const { vehicles, vehiclesIsLoading, vehiclesError, vehicleRequest } = useAppSelector(state => state.store.vehicle);
    const { driversIsLoading } = useAppSelector(state => state.store.driver);
    const { trailersIsLoading } = useAppSelector(state => state.store.trailer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(getAllVehicleAction());
        dispatch(getAllDriverAction())
        dispatch(getAllTrailerAction())
    }, [dispatch]);

    const onCreate = (data: CreateVehicle) => {
        dispatch(createVehicleAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('vehicleCreatedSuccessfully'));
            }
        });
    };

    const onUpdate = (data: UpdateVehicle) => {
        dispatch(updateVehicleAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenUpdateDialog(false);
                toast.success(t('vehicleUpdatedSuccessfully'));
            }
        });
    };

    const onDelete = (id: string) => {
        dispatch(deleteVehicleAction(id)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenDeleteDialog(false);
                toast.success(t('vehicleDeletedSuccessfully'));
            }
        });
    };

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    };

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    };

    const handleUpdateDialogOpen = (vehicle: VehicleType) => {
        setSelectedVehicle(vehicle);
        setOpenUpdateDialog(true);
    };

    const handleDeleteDialogOpen = (vehicle: VehicleType) => {
        setSelectedVehicle(vehicle);
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="body1"
                        mb={1}
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            marginTop: '1rem',
                        }}
                    >
                        {t('vehicles')}
                    </Typography>
                    <Button 
                        variant='contained' 
                        color='secondary' 
                        onClick={handleCreateDialogOpen}
                        disabled={vehiclesIsLoading || vehicleRequest || driversIsLoading || trailersIsLoading}
                        >
                        {t('addVehicle')}
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <VehicleTable
                        openCreateDialog={openCreateDialog}
                        openUpdateDialog={openUpdateDialog}
                        isLoading={vehiclesIsLoading || vehicleRequest || driversIsLoading || trailersIsLoading}
                        vehicles={vehicles}
                        selectedVehicle={selectedVehicle}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        handleUpdateDialogClose={handleUpdateDialogClose}
                        handleDeleteDialogClose={handleDeleteDialogClose}
                        handleUpdateDialogOpen={handleUpdateDialogOpen}
                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                </Grid>
            </Grid>
            <NewVehicleForm open={openCreateDialog} onCreate={onCreate} onClose={handleCreateDialogClose} requesting={vehicleRequest} />
            <DeleteVehicleDialog selectedVehicle={selectedVehicle} open={openDeleteDialog} onDelete={onDelete} onClose={handleDeleteDialogClose} requesting={vehicleRequest} />
            <UpdateVehicleDialog selectedVehicle={selectedVehicle} open={openUpdateDialog} onUpdate={onUpdate} onClose={handleUpdateDialogClose} requesting={vehicleRequest} />
        </>
    );
}
