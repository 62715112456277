
import { UpdateDriver, CreateDriver } from 'types/driver'
import axiosInstance from 'services/axiosInstance'


export const get_all_driver_service = async () => {
  const response = await axiosInstance.get('/driver/');
  return response;
};


export const get_driver_by_id_service = async (id:string) => {
    const response = await axiosInstance.get(`/driver/${id}/`);
    return response;
  };

export const create_driver_service = async (data: CreateDriver) => {
    const response = await axiosInstance.post('/driver/', data);
    return response;
    }

export const update_driver_service = async (data:UpdateDriver) => {
  const response = await axiosInstance.put(`/driver/${data.id}/`, {
    name: data.name,
  });
  return response;
}

export const delete_driver_service = async (id:string) => {
  const response = await axiosInstance.delete(`/driver/${id}/`);
  return response;
}