import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { Material, UpdateMaterial } from 'types/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface UpdateMaterialDialogProps {
    selectedMaterial: Material | null;
    onUpdate: (values: UpdateMaterial) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('materialNameRequired'),
});

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const UpdateMaterialDialog: React.FC<UpdateMaterialDialogProps> = ({ selectedMaterial, onUpdate, onClose, requesting, open }) => {
    const { t } = useTranslation();

    const initialValues: UpdateMaterial = {
        id: selectedMaterial?.material_id || '',
        name: selectedMaterial?.name || '',
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <CardHeader
                    subheader={t('fillFormUpdateMaterial')}
                    title={t('updateMaterial')}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onUpdate(values);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        name="name"
                                        label={t('materialName')}
                                        fullWidth
                                        margin="normal"
                                        required
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && t(errors.name!)}
                                    />
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <Box mt={2} display="flex" justifyContent="flex-end">
                                        <Button onClick={onClose} color="secondary" variant="outlined" sx={{ marginRight: 2 }}>
                                            {t('cancel')}
                                        </Button>
                                        <Button type="submit" color="warning" variant="contained" disabled={requesting}>
                                            {requesting ? <CircularProgress size={24} sx={{ color: 'text.primary' }} /> : t('update')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default UpdateMaterialDialog;
