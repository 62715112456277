import React from 'react'
import { Box, Divider, IconButton, List, MenuItem, Toolbar } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Navlist } from 'components/common/navList'
import LanguageSwitch from 'components/language/languageSwitch';
import { useTranslation } from 'react-i18next';

interface DrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ open, toggleDrawer }) => {
    const { t } = useTranslation()
    
    const handleLanguageChange = (newLanguage: string) => {
        toast.info(t('SwitchLanguage'));
        toggleDrawer()
    }

    const drawerWidth: number = 300;

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
        }),
    );
    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Box style={{height:'100%',display:'flex',justifyContent:'space-between',flexDirection:'column' }}>
                <List component="nav">
                    {Navlist()}
                </List>
                {
                    open &&                 
                    <List component="nav" style={{display:'flex',flexDirection:'column', alignItems:'center', justifyItems:'center'}}>
                        <LanguageSwitch onLanguageChange={handleLanguageChange} />
                    </List>
                }

            </Box>
        </Drawer>
    )
}

export default Drawer