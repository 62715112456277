import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends Omit<ButtonProps, 'variant'> {
  label: string;
  variant: 'text' | 'outlined' | 'contained';
}

export const CustomButton: React.FC<CustomButtonProps> = ({ label, variant, ...props }) => {
  return (
    <Button variant={variant} {...props}>
      {label}
    </Button>
  );
};