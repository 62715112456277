import { Box } from '@mui/material';
import DashboardView from 'components/dashboard';

export interface IDashboardPageProps {
}

export default function DashboardPage (props: IDashboardPageProps) {

  return (
    <Box
        id={'document_view'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <DashboardView />
    </Box>
  );
}
