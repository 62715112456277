import * as React from 'react';
import { Box } from '@mui/material';
import VehicleView from 'components/vehicle';

export interface IVehiclePageProps {
}

export default function VehiclePage (props: IVehiclePageProps) {
  return (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      > 
        <VehicleView />
    </Box>
  );
}
