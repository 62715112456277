
import React, { useEffect } from 'react';
import { useAppSelector } from 'store/index';


const useUser = () => {
    const { user } = useAppSelector(state => state.store.auth);
    
    return {user};
  };
  
  export default useUser;