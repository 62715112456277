import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriverState } from 'types/driver';
import { 
    getAllDriverAction, 
    getDriverByIDAction, 
    createDriverAction,
    updateDriverAction,
    deleteDriverAction
} from 'store/actions/driver';


const initialState: DriverState = {
    drivers: [],
    driversIsLoading: false,
    driversError: '',
    driverRequest: false
};

export const driverSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL DRIVER START *********** \\
    .addCase(getAllDriverAction.pending, (state) => {
        state.driversIsLoading = true;
        state.driversError = '';
    })
    .addCase(getAllDriverAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.drivers = action.payload.data;
        state.driversIsLoading = false;
    })
    .addCase(getAllDriverAction.rejected, (state, action: PayloadAction<any>) => {
        state.driversError = action.payload.message;
        state.driversIsLoading = false;
    })
    // *********** GET ALL DRIVER END *********** \\
    // *********** CREATE DRIVER START *********** \\
    .addCase(createDriverAction.pending, (state) => {
        state.driversIsLoading = true;
        state.driversError = '';
    })
    .addCase(createDriverAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.drivers.push(action.payload.data);
        state.driversIsLoading = false;
    })
    .addCase(createDriverAction.rejected, (state, action: PayloadAction<any>) => {
        state.driversError = action.payload.message;
        state.driversIsLoading = false;
    })
    // *********** CREATE DRIVER END *********** \\
    // *********** UPDATE DRIVER START *********** \\
    .addCase(updateDriverAction.pending, (state) => {
        state.driversIsLoading = true;
        state.driversError = '';
    })
    .addCase(updateDriverAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.drivers.findIndex((driver) => driver.driver_id === action.payload.data.driver_id);
        state.drivers[index] = action.payload.data;
        state.driversIsLoading = false;
    })
    .addCase(updateDriverAction.rejected, (state, action: PayloadAction<any>) => {
        state.driversError = action.payload.message;
        state.driversIsLoading = false;
    })
    // *********** UPDATE DRIVER END *********** \\
    // *********** DELETE DRIVER START *********** \\
    .addCase(deleteDriverAction.pending, (state) => {
        state.driversIsLoading = true;
        state.driversError = '';
    })
    .addCase(deleteDriverAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.drivers = state.drivers.filter((driver) => driver.driver_id !== action.payload.data.driver_id);
        state.driversIsLoading = false;
    })
    .addCase(deleteDriverAction.rejected, (state, action: PayloadAction<any>) => {
        state.driversError = action.payload.message;
        state.driversIsLoading = false;
    })
    // *********** DELETE DRIVER END *********** \\
  },
  
});
export const { } = driverSlice.actions

export default driverSlice.reducer;