import React from 'react';
import { Box, Button, Typography, Modal, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Material } from 'types/material';

interface DeleteMaterialDialogProps {
    selectedMaterial: Material | null;
    onDelete: (id: string) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const DeleteMaterialDialog: React.FC<DeleteMaterialDialogProps> = ({ selectedMaterial, onDelete, onClose, requesting, open }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        if (selectedMaterial && selectedMaterial.material_id) {
            onDelete(selectedMaterial.material_id);
        }
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    {t('confirmDeletion')}
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    {t('areYouSureDelete', { $1: selectedMaterial?.name })}
                </Typography>
                <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="error" variant="contained" disabled={requesting}>
                        {requesting ? <CircularProgress size={24} sx={{ color: 'text.primaryChannel' }} /> : t('delete')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteMaterialDialog;
