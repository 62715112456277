import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/he';

interface LiveClockProps {
  field_name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  disable_auto_time: boolean;
  refresh: boolean;
}

const fieldStyles = {
  '& .MuiInputBase-root': {
      height: '40px',
  },
  '& .MuiFormLabel-root': {
      fontSize: '14px',
      fontWeight: 'bold',
  },
};

const LiveClock: React.FC<LiveClockProps> = ({ field_name, value, onChange, error, helperText, label, disable_auto_time, refresh }) => {
  const [liveTime, setLiveTime] = useState<string>(dayjs().locale('he').format('HH:mm'));
  const [manualTime, setManualTime] = useState<string | null>(null);
  const [disableAuto, setDisableAuto] = useState<boolean>(disable_auto_time);

  useEffect(() => {
    if (value && value !== '') {
      setManualTime(dayjs(value, 'HH:mm').format('HH:mm'));
    }else{
      setManualTime(null);
    }

    if (!disable_auto_time && !disableAuto) {
      const timerID = setInterval(() => {
        const currentTime = dayjs().locale('he').format('HH:mm');
        setLiveTime(currentTime);
        onChange(field_name, String(currentTime));
      }, 1000);

      return () => clearInterval(timerID);
    }
  }, [value, disable_auto_time]);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTime = event.target.value;
    setManualTime(selectedTime);
    setDisableAuto(true);
    onChange(field_name, String(selectedTime));
  };

  const getCurrentTime = () => {
    return manualTime || liveTime;
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '88%',
      justifyContent: 'flex-end',
    }}>
      <TextField
        type="time"
        label={label || 'Time'}
        value={getCurrentTime()}
        sx={fieldStyles}
        onChange={handleTimeChange}
        InputLabelProps={{ shrink: true }}
        inputProps={{ step: 60 }}
        error={error}
        helperText={helperText}
        fullWidth
      />
    </Box>
  );
};

export default LiveClock;
