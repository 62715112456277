import React from 'react';
import { Box, CardHeader, Modal, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { Document, UpdateDocument } from 'types/document';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DocumentForm from './documentForm';
import { Height } from '@mui/icons-material';

interface UpdateDocumentDialogProps {
    selectedDocument: Document | null;
    onUpdate: (values: UpdateDocument) => void;
    onDelete: (id: string) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const modalStyles = {
    position: 'absolute' as const,
    width:'80%',
    maxWidth: '90%',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
};

const UpdateDocumentDialog: React.FC<UpdateDocumentDialogProps> = ({ selectedDocument, onUpdate, onDelete, onClose, requesting, open }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}>
                    <CardHeader
                        subheader={t('fillFormUpdateDocument')}
                        title={t('updateDocument')}
                    />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DocumentForm 
                    // screen='update'
                    onCreate={() => {}} 
                    selectedDocument={selectedDocument}
                    requesting={requesting} 
                    onClose={onClose} 
                    onDelete={onDelete}
                    onUpdate={onUpdate} 
                />
            </Box>
        </Modal>
    );
};

export default UpdateDocumentDialog;
