import { 
    DashboardPage, 
    DriverPage,
    DocumentPage,
    CustomerPage,
    VehiclePage,
    MaterialPage,
    LoginPage, 
    NotFoundPage,
    TrailerPage
} from 'pages/index';

import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PhishingIcon from '@mui/icons-material/Phishing';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Home } from '@mui/icons-material';

import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';

import { Route } from 'react-router-dom';

export const routesArray = [
    {
        path: '/',
        element: <PrivateRoute><DashboardPage /></PrivateRoute>,
        icon: <Home />,
        label: "home",
        nav: true,
        requires_superuser:false,
    },
    {
        path:'/documents',
        element: <PrivateRoute><DocumentPage /></PrivateRoute>,
        icon: <DocumentScannerIcon />,
        label: 'documents',
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/drivers',
        element: <PrivateRoute><DriverPage /></PrivateRoute>,
        icon: <PeopleIcon />,
        label: "drivers",
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/vehicles',
        element: <PrivateRoute><VehiclePage /></PrivateRoute>,
        icon: <LocalShippingIcon />,
        label: 'vehicles',
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/trailers',
        element: <PrivateRoute><TrailerPage /></PrivateRoute>,
        icon: <PhishingIcon />,
        label: 'trailers',
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/customers',
        element: <PrivateRoute><CustomerPage /></PrivateRoute>,
        icon: <FamilyRestroomIcon />,
        label: 'customers',
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/material',
        element: <PrivateRoute><MaterialPage /></PrivateRoute>,
        icon: <PanoramaVerticalSelectIcon />,
        label: 'materials',
        nav: true,
        requires_superuser:false,
    },
    {
        path: '/login',
        element: <PublicRoute><LoginPage /></PublicRoute>,
        icon: <PersonIcon />,
        label: 'login',
        nav: false,
        requires_superuser:false,
    },
    {
        path: '*',
        element: <NotFoundPage />,
        label: 'Not Found',
        nav: false,
        requires_superuser:false,
    },
];


export const renderedRoutes = (is_superuser: boolean = false) => (
  routesArray.map((route, index) => (
    (is_superuser && route.requires_superuser) || !route.requires_superuser
      ? <Route key={index} path={route.path} element={route.element} />
      : null
  ))
);