import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { CreateCustomer } from 'types/customer';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface NewCustomerFormProps {
    onCreate: (values: CreateCustomer) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const NewCustomerForm: React.FC<NewCustomerFormProps> = ({ onCreate, onClose, requesting, open }) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('customerNameRequired')),
        address: Yup.string(),
        phone: Yup.string().matches(/^[0-9]+$/, t('phoneNotValid')),
        second_phone: Yup.string().matches(/^[0-9]+$/, t('secondPhoneNotValid')),
    });

    const initialValues: CreateCustomer = {
        name: '',
        address: '',
        phone: '',
        second_phone: '',
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <CardHeader
                    subheader={t('fillFormAddCustomer')}
                    title={t('addCustomer')}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onCreate(values);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label={t('customerName')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && String(t(errors.name!))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="address"
                                            label={t('address')}
                                            fullWidth
                                            margin="normal"
                                            error={touched.address && Boolean(errors.address)}
                                            helperText={touched.address && String(t(errors.address!))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="phone"
                                            label={t('phone')}
                                            fullWidth
                                            margin="normal"
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && String(t(errors.phone!))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="second_phone"
                                            label={t('secondPhone')}
                                            fullWidth
                                            margin="normal"
                                            error={touched.second_phone && Boolean(errors.second_phone)}
                                            helperText={touched.second_phone && String(t(errors.second_phone!))}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                                    <Button onClick={onClose} color="secondary" variant="outlined" style={{ marginRight: 8 }}>
                                        {t('cancel')}
                                    </Button>
                                    <Button type="submit" color="primary" variant="contained" disabled={requesting}>
                                        {requesting ? <CircularProgress size={24} sx={{ color: 'text.primaryChannel' }} /> : t('submit')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export default NewCustomerForm;
