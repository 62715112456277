import React from 'react';
import { Document } from 'types/document';
import dayjs from 'dayjs';

interface A5BoxProps {
  document: Document;
  hide?: boolean;
}

export const A5Box: React.FC<A5BoxProps> = ({ document, hide }) => {
  const removeSeconds = (time: string) => {
    return time ? time.slice(0, 5) : '';
  };

  console.log(document)

  return (
    <div
      id="printableArea"
      style={{
        display: hide ? 'none' : 'block',
        width: '20.9cm',
        height: '14.6cm',
        boxSizing: 'border-box',
        position: 'relative',
        backgroundColor: 'transparent',
        color: 'black',
        borderRadius: '2px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          paddingTop: '1.9cm',
          paddingLeft: '1.8cm',
          paddingBottom: '1.3cm',
          paddingRight: '1.5cm',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        <div style={{ textAlign: 'center', borderBottom: '1px solid rgba(0,0,0,0.22)' }}>
          <span style={{ fontWeight: 'bold' }}>תעודת שקילה מס:</span> {document?.document_id ?? ''}
        </div>
        {/* First Section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,0.22)' }}>
          <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>מספר רכב: </span>
              <span>{document?.vehicle?.vehicle_number ?? ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>ספק/לקוח: </span>
              <span style={{ fontWeight: 'bold' }}> </span>
              <span>{document?.customer?.name ?? ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>סוג חומר: </span>
              <span>{document?.material?.name ?? ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>אסמכתא: </span>
              <span>{''}</span>
            </div>
          </div>

          <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>שם נהג: </span>
              <span>{document?.driver?.name ?? ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}> מוביל: </span>
              <span>
                {document?.trailer?.trailer_number ?? ''}
              </span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>יעד/מקור: </span>
              <span>{document?.go_to ?? ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>הערות: </span>
              <span>{document?.comment?.length > 30 ? `${document.comment.slice(0, 30)}...` : document?.comment ?? ''}</span>
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(0,0,0,0.22)' }}>
          <div>
            <span>ברוטו: </span>
            <span style={{ fontWeight: 'bold' }}>{Number(document?.weight_after) ?? ''} ק"ג</span>
          </div>
          <div>
            <span>טרה: </span>
            <span style={{ fontWeight: 'bold' }}>{Number(document?.weight_before) ?? ''} ק"ג</span>
          </div>
          <div>
            <span>נטו: </span>
            <span style={{ fontWeight: 'bold' }}>{document?.weight_after && document?.weight_before ? Number(document.weight_after) - Number(document.weight_before) : ''} ק"ג</span>
          </div>
        </div>

        {/* Third Section */}
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>תאריך: </span>
              <span>{document?.date ? dayjs(document.date).format('DD/MM/YYYY') : ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>שעת כניסה: </span>
              <span>{document?.entry_time ? removeSeconds(document.entry_time) : ''}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>שעת יציאה: </span>
              <span>{document?.exit_time ? removeSeconds(document.exit_time) : ''}</span>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>שוקל: </span>
              <span style={{
                borderBottom: '1.5px solid #000',
              }}>{document?.created_by_user?.first_name + ' ' + document?.created_by_user?.last_name}</span>

            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>נהג/ חתימה:</span>
              <span>____________</span>
            </div>
            {/* <div>
              <span style={{ fontWeight: 'bold' }}> שם וחתימת מקבל המטען: </span>
              <span>____________</span>
            </div> */}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <span style={{ fontWeight: 'bold' }}>תאריך הדפסה: </span>
            <span>{dayjs().format('HH:mm DD/MM/YYYY')}</span>
          </div>
        </div>

        {/* Fifth Section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px dashed #000' }}>
          <div>
            <span style={{ fontWeight: 'bold' }}> שם וחתימת מקבל המטען: </span>
            <span>____________</span>
          </div>
          {/* <div>
            <span style={{ fontWeight: 'bold' }}>כתובת לקוח: </span>
            <span>{document?.customer?.address ?? ''}</span>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>טלפון לקוח: </span>
            <span>{document?.customer?.phone ?? ''}</span>
          </div> */}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={document?.qr_code ?? ''} width={50} height={50} alt="QR Code" />
            <div>לאימות התעודה-נא לסרוק את QR</div>
          </div>
        </div>

        <div style={{ textAlign: 'right', fontSize: '0.8rem' }}>
          הופק ע"י קרט נט מערכות- טל: 0508524667
        </div>

      </div>
    </div>
  );
};
