import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { CreateMaterial } from 'types/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface NewMaterialFormProps {
    onCreate: (values: CreateMaterial) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('materialNameRequired'),
});

const initialValues: CreateMaterial = {
    name: '',
};

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const NewMaterialForm: React.FC<NewMaterialFormProps> = ({ onCreate, onClose, requesting, open }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <CardHeader
                    subheader={t('fillFormAddMaterial')}
                    title={t('addNewMaterial')}
                    sx={{ textAlign: 'center', mb: 2 }}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onCreate(values);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label={t('materialName')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && t(errors.name!)}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <Box mt={2} display="flex" justifyContent="flex-end">
                                            <Button onClick={onClose} color="secondary" variant="outlined" sx={{ marginRight: 2 }}>
                                                {t('cancel')}
                                            </Button>
                                            <Button type="submit" color="primary" variant="contained" disabled={requesting}>
                                                {requesting ? <CircularProgress size={24} sx={{ color: 'text.primary' }} /> : t('submit')}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

export default NewMaterialForm;
