import { useAppSelector } from 'store/index';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from 'components/common/loader';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const { isAuthenticated, loading } = useAppSelector(state => state.store.auth);
  
  if (loading) {
    return <Loader loading={loading} />
  }

  if (isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default PublicRoute;