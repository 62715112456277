import * as React from 'react';
import { Box } from '@mui/material';
import MaterialView from 'components/material';

export interface IMaterialPageProps {
}

export default function MaterialPage (props: IMaterialPageProps) {
  return (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      > 
        <MaterialView />
    </Box>
  );
}
