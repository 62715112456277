
import { UpdateTrailer, CreateTrailer } from 'types/trailer'
import axiosInstance from 'services/axiosInstance'


export const get_all_trailer_service = async () => {
  const response = await axiosInstance.get('/trailer/');
  return response;
};


export const get_trailer_by_id_service = async (id:string) => {
    const response = await axiosInstance.get(`/trailer/${id}/`);
    return response;
  };

export const create_trailer_service = async (data: CreateTrailer) => {
    const response = await axiosInstance.post('/trailer/', data);
    return response;
    }

export const update_trailer_service = async (data:UpdateTrailer) => {
  const response = await axiosInstance.put(`/trailer/${data.id}/`, {
    name: data.name,
    trailer_number: data.trailer_number,
  });
  return response;
}

export const delete_trailer_service = async (id:string) => {
  const response = await axiosInstance.delete(`/trailer/${id}/`);
  return response;
}