import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateMaterial, UpdateMaterial} from 'types/material';
import { 
    get_all_material_service, 
    get_material_by_id_service, 
    create_material_service,
    update_material_service,
    delete_material_service } from 'services/material'

export const getAllMaterialAction = createAsyncThunk(
    'material/getAllMaterial',
    async (_, thunkAPI) => {
        try {
            return await get_all_material_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getMaterialByIDAction = createAsyncThunk(
    'material/getAllMaterial',
    async (id:string, thunkAPI) => {
        try {
            return await get_material_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createMaterialAction = createAsyncThunk(
    'material/createMaterial',
    async (data: CreateMaterial, thunkAPI) => {
        try {
            return await create_material_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateMaterialAction = createAsyncThunk(
    'material/updateMaterial',
    async (data: UpdateMaterial, thunkAPI) => {
        try {
            return await update_material_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteMaterialAction = createAsyncThunk(
    'material/deleteMaterial',
    async (id: string, thunkAPI) => {
        try {
            return await delete_material_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
