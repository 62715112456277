import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateDriver, UpdateDriver} from 'types/driver';
import { 
    get_all_driver_service, 
    get_driver_by_id_service, 
    create_driver_service,
    update_driver_service,
    delete_driver_service } from 'services/driver'

export const getAllDriverAction = createAsyncThunk(
    'driver/getAllDriver',
    async (_, thunkAPI) => {
        try {
            return await get_all_driver_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getDriverByIDAction = createAsyncThunk(
    'driver/getAllDriver',
    async (id:string, thunkAPI) => {
        try {
            return await get_driver_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createDriverAction = createAsyncThunk(
    'driver/createDriver',
    async (data: CreateDriver, thunkAPI) => {
        try {
            return await create_driver_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateDriverAction = createAsyncThunk(
    'driver/updateDriver',
    async (data: UpdateDriver, thunkAPI) => {
        try {
            return await update_driver_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteDriverAction = createAsyncThunk(
    'driver/deleteDriver',
    async (id: string, thunkAPI) => {
        try {
            return await delete_driver_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
