import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentState } from 'types/document';
import { 
    getAllDocumentAction, 
    getDocumentByIDAction, 
    getDocumentPaginationAction,
    getDocumentByUUIDAction,
    createDocumentAction,
    updateDocumentAction,
    deleteDocumentAction,
} from 'store/actions/document';


const initialState: DocumentState = {
    paginationData:{
        next: null,
        previous: null,
        count: 0,
        results: []
    },
    latestDocuments: [],
    documents: [],
    page: 0,
    limit:0,
    totalDocuments: 0,
    previewDocument: null,
    documentsIsLoading: false,
    documentsError: '',
    documentRequest: false
};

export const DocumentSlice = createSlice({
  name: 'Document',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL Document START *********** \\
    .addCase(getAllDocumentAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentsError = '';
    })
    .addCase(getAllDocumentAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.documents = action.payload.data;
        state.documentsIsLoading = false;
    })
    .addCase(getAllDocumentAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
    })
    // *********** GET ALL Document END *********** \\
    // *********** GET Document PAGINATION START *********** \\
    .addCase(getDocumentPaginationAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentsError = '';
    })
    .addCase(getDocumentPaginationAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.paginationData = action.payload.data;
        state.documentsIsLoading = false;
    })
    .addCase(getDocumentPaginationAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
    })
    // *********** GET Document PAGINATION END ********
    // *********** GET Document BY UUID START *********** \\
    .addCase(getDocumentByUUIDAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentsError = '';
    })
    .addCase(getDocumentByUUIDAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.previewDocument = action.payload.data;
        state.documentsIsLoading = false;
    })
    .addCase(getDocumentByUUIDAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
    })
    // *********** GET Document BY UUID END *********** \\
    // *********** CREATE Document START *********** \\
    .addCase(createDocumentAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentRequest = true;
        state.documentsError = '';
    })
    .addCase(createDocumentAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.paginationData.results.unshift(action.payload.data);
        state.documentsIsLoading = false;
        state.documentRequest = false;
    })
    .addCase(createDocumentAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
        state.documentRequest = false;
    })
    // *********** CREATE Document END *********** \\
    // *********** UPDATE Document START *********** \\
    .addCase(updateDocumentAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentsError = '';
    })
    .addCase(updateDocumentAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.paginationData.results.findIndex((document) => document.document_id === action.payload.data.document_id);
        state.paginationData.results[index] = action.payload.data;
        state.documentsIsLoading = false;
    })
    .addCase(updateDocumentAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
    })
    // *********** UPDATE Document END *********** \\
    // *********** DELETE Document START *********** \\
    .addCase(deleteDocumentAction.pending, (state) => {
        state.documentsIsLoading = true;
        state.documentsError = '';
    })
    .addCase(deleteDocumentAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.paginationData.results =state.paginationData.results.filter((document) => document.document_id !== action.payload.data.document_id);
        state.documentsIsLoading = false;
    })
    .addCase(deleteDocumentAction.rejected, (state, action: PayloadAction<any>) => {
        state.documentsError = action.payload.message;
        state.documentsIsLoading = false;
    })
    // *********** DELETE Document END *********** \\
  },
  
});
export const { } = DocumentSlice.actions

export default DocumentSlice.reducer;