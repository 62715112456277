import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerState } from 'types/customer';
import { 
    getAllCustomerAction, 
    getCustomerByIDAction, 
    createCustomerAction,
    updateCustomerAction,
    deleteCustomerAction
} from 'store/actions/customer';


const initialState: CustomerState = {
    customers: [],
    customersIsLoading: false,
    customersError: '',
    customerRequest: false
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL Customer START *********** \\
    .addCase(getAllCustomerAction.pending, (state) => {
        state.customersIsLoading = true;
        state.customersError = '';
    })
    .addCase(getAllCustomerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.customers = action.payload.data;
        state.customersIsLoading = false;
    })
    .addCase(getAllCustomerAction.rejected, (state, action: PayloadAction<any>) => {
        state.customersError = action.payload.message;
        state.customersIsLoading = false;
    })
    // *********** GET ALL Customer END *********** \\
    // *********** CREATE Customer START *********** \\
    .addCase(createCustomerAction.pending, (state) => {
        state.customersIsLoading = true;
        state.customersError = '';
    })
    .addCase(createCustomerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.customers.push(action.payload.data);
        state.customersIsLoading = false;
    })
    .addCase(createCustomerAction.rejected, (state, action: PayloadAction<any>) => {
        state.customersError = action.payload.message;
        state.customersIsLoading = false;
    })
    // *********** CREATE Customer END *********** \\
    // *********** UPDATE Customer START *********** \\
    .addCase(updateCustomerAction.pending, (state) => {
        state.customersIsLoading = true;
        state.customersError = '';
    })
    .addCase(updateCustomerAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.customers.findIndex((Customer) => Customer.customer_id === action.payload.data.customer_id);
        state.customers[index] = action.payload.data;
        state.customersIsLoading = false;
    })
    .addCase(updateCustomerAction.rejected, (state, action: PayloadAction<any>) => {
        state.customersError = action.payload.message;
        state.customersIsLoading = false;
    })
    // *********** UPDATE Customer END *********** \\
    // *********** DELETE Customer START *********** \\
    .addCase(deleteCustomerAction.pending, (state) => {
        state.customersIsLoading = true;
        state.customersError = '';
    })
    .addCase(deleteCustomerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.customers = state.customers.filter((Customer) => Customer.customer_id !== action.payload.data.customer_id);
        state.customersIsLoading = false;
    })
    .addCase(deleteCustomerAction.rejected, (state, action: PayloadAction<any>) => {
        state.customersError = action.payload.message;
        state.customersIsLoading = false;
    })
    // *********** DELETE Customer END *********** \\
  },
  
});
export const { } = customerSlice.actions

export default customerSlice.reducer;