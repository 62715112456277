import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { CreateTrailer } from 'types/trailer';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface NewTrailerFormProps {
    onCreate: (values: CreateTrailer) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const NewTrailerForm: React.FC<NewTrailerFormProps> = ({ onCreate, onClose, requesting, open }) => {
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('trailerNameRequired')),
        trailer_number: Yup.string().required(t('trailerNumberRequired')),
    });

    const initialValues: CreateTrailer = {
        name: '',
        trailer_number: '',
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <CardHeader
                    subheader={t('fillFormAddTrailer')}
                    title={t('addNewTrailer')}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onCreate(values);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label={t('trailerName')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && String(t(errors.name!))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            name="trailer_number"
                                            label={t('trailerNumber')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.trailer_number && Boolean(errors.trailer_number)}
                                            helperText={touched.trailer_number && String(t(errors.trailer_number!))}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                                    <Button onClick={onClose} color="secondary" variant="outlined" style={{ marginRight: 8 }}>
                                        {t('cancel')}
                                    </Button>
                                    <Button type="submit" color="primary" variant="contained" disabled={requesting}>
                                        {requesting ? <CircularProgress size={24} sx={{ color: 'text.primaryChannel' }} /> : t('submit')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export default NewTrailerForm;
