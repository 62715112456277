import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrailerState } from 'types/trailer';
import { 
    getAllTrailerAction, 
    getTrailerByIDAction, 
    createTrailerAction,
    updateTrailerAction,
    deleteTrailerAction
} from 'store/actions/trailer';


const initialState: TrailerState = {
    trailers: [],
    trailersIsLoading: false,
    trailersError: '',
    trailerRequest: false
};

export const trailerSlice = createSlice({
  name: 'trailer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL trailer START *********** \\
    .addCase(getAllTrailerAction.pending, (state) => {
        state.trailersIsLoading = true;
        state.trailersError = '';
    })
    .addCase(getAllTrailerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.trailers = action.payload.data;
        state.trailersIsLoading = false;
    })
    .addCase(getAllTrailerAction.rejected, (state, action: PayloadAction<any>) => {
        state.trailersError = action.payload.message;
        state.trailersIsLoading = false;
    })
    // *********** GET ALL trailer END *********** \\
    // *********** CREATE trailer START *********** \\
    .addCase(createTrailerAction.pending, (state) => {
        state.trailersIsLoading = true;
        state.trailersError = '';
    })
    .addCase(createTrailerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.trailers.push(action.payload.data);
        state.trailersIsLoading = false;
    })
    .addCase(createTrailerAction.rejected, (state, action: PayloadAction<any>) => {
        state.trailersError = action.payload.message;
        state.trailersIsLoading = false;
    })
    // *********** CREATE trailer END *********** \\
    // *********** UPDATE trailer START *********** \\
    .addCase(updateTrailerAction.pending, (state) => {
        state.trailersIsLoading = true;
        state.trailersError = '';
    })
    .addCase(updateTrailerAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.trailers.findIndex((trailer) => trailer.trailer_id === action.payload.data.trailer_id);
        state.trailers[index] = action.payload.data;
        state.trailersIsLoading = false;
    })
    .addCase(updateTrailerAction.rejected, (state, action: PayloadAction<any>) => {
        state.trailersError = action.payload.message;
        state.trailersIsLoading = false;
    })
    // *********** UPDATE trailer END *********** \\
    // *********** DELETE trailer START *********** \\
    .addCase(deleteTrailerAction.pending, (state) => {
        state.trailersIsLoading = true;
        state.trailersError = '';
    })
    .addCase(deleteTrailerAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.trailers = state.trailers.filter((trailer) => trailer.trailer_id !== action.payload.data.trailer_id);
        state.trailersIsLoading = false;
    })
    .addCase(deleteTrailerAction.rejected, (state, action: PayloadAction<any>) => {
        state.trailersError = action.payload.message;
        state.trailersIsLoading = false;
    })
    // *********** DELETE trailer END *********** \\
    
  },
  
});
export const { } = trailerSlice.actions

export default trailerSlice.reducer;