import * as React from 'react';
import { Box } from '@mui/material';
import DocumentView from 'components/document';

export interface IDocumentPageProps {
}

export default function DocumentPage (props: IDocumentPageProps) {
  return (
    <Box
        id={'document_view'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      > 
        <DocumentView />
    </Box>
  );
}
