import LoginForm from 'components/login/loginForm'
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { loginAction } from 'store/actions/auth'
import { authActionType } from 'types/auth'

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector(state => state.store.auth)

  const handleSubmit = (data: authActionType) => {
    dispatch(loginAction(data))
  };

  return (

    <LoginForm loading={loading} error={error} onSubmit={handleSubmit} />

  );
}