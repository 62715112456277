import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import {
    Box, Button, TextField, Grid, Autocomplete, FormControl, FormLabel, FormGroup, FormControlLabel,
    Checkbox, FormHelperText, Paper,
    CircularProgress
} from '@mui/material';
import LiveClock from 'components/common/liveClock';
import { Cancel, Update, Create, Refresh, Delete, Print } from '@mui/icons-material'
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/he';
import { CreateDocument, Document, UpdateDocument } from 'types/document';
import { useAppSelector } from 'store/index';
import useUser from 'hooks/useUser';
import { A5Box } from './a5Document';
import dayjs from 'dayjs';

interface DocumentFormProps {
    screen?: string;
    onCreate: (values: CreateDocument) => void;
    onClose?: () => void;
    requesting: boolean;
    selectedDocument?: Document | null;
    handleSelectDocument?: (document: Document | null) => void;
    onDelete?: (id: string) => void;
    onUpdate?: (values: UpdateDocument, close: boolean) => void;
}

const validationSchema = Yup.object().shape({
    entry_time: Yup.string().required('timeRequired'),
    exit_time: Yup.string().required('timeRequired'),
    date: Yup.string().required('dateRequired'),
    driver_id: Yup.string().required('driverRequired'),
    vehicle_id: Yup.string().required('vehicleRequired'),
    material_id: Yup.string().required('materialRequired'),
    customer_id: Yup.string().required('customerRequired'),
    go_to: Yup.string().required(),
    comment: Yup.string(),
    weight_before: Yup.number()
        .required('weightBeforeRequired')
        .positive('weightMustBePositive'),
    weight_after: Yup.number()
        .required('weightAfterRequired')
        .positive('weightMustBePositive')
    // .test('is-greater', 'weightAfterMustBeGreater', function (value) {
    //     const { weight_before } = this.parent;
    //     return value > weight_before;
    // })
    ,
    measured_thing: Yup.string().required('measuredThingRequired'),
    qr_code: Yup.string(),
});

const fieldStyles = {
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiFormLabel-root': {
        fontSize: '14px',
        fontWeight: 'bold',
    },
};

const autocompleteStyles = {
    '& .MuiFormControl-root': {
        width: '100%',
    },
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiAutocomplete-inputRoot': {
        padding: '0px 8px',
    },
    '& .MuiFormLabel-root': {
        fontSize: '14px',
        fontWeight: 'bold',
    },
};

const DocumentForm: React.FC<DocumentFormProps> = ({ onCreate, onClose, requesting, selectedDocument, handleSelectDocument, onUpdate, onDelete, screen }) => {
    const { t } = useTranslation();
    const { user } = useUser();
    const { drivers } = useAppSelector((state) => state.store.driver);
    const { vehicles } = useAppSelector((state) => state.store.vehicle);
    const { materials } = useAppSelector((state) => state.store.material);
    const { customers } = useAppSelector((state) => state.store.customer);
    const { trailers } = useAppSelector((state) => state.store.trailer);

    const [initialDate, setInitialDate] = useState<string>('');
    const [initialTime, setInitialTime] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(false);

    const triggerRefresh = () => {
      setRefresh((prev) => !prev); 
    };

    useEffect(() => {
        const updateTimeAndDate = () => {
            const now = new Date();
            if (!selectedDocument) {
                setInitialTime(now.toTimeString().substring(0, 5));
                setInitialDate(now.toISOString().substring(0, 10));
            }
        };

        updateTimeAndDate();
    }, [selectedDocument]);

    const initialValues: CreateDocument | Document | UpdateDocument = selectedDocument ? {
        entry_time: selectedDocument.entry_time,
        exit_time: selectedDocument.exit_time,
        date: selectedDocument.date,
        created_by_user: user ? user.user_id : '1',
        driver_id: selectedDocument.driver.driver_id,
        driver_name: selectedDocument.driver.name,
        vehicle_id: selectedDocument.vehicle.vehicle_id,
        vehicle_name: selectedDocument.vehicle.name,
        trailer_id: selectedDocument.trailer?.trailer_id,
        trailer_name: selectedDocument.trailer?.name,
        material_id: selectedDocument.material.material_id,
        material_name: selectedDocument.material.name,
        customer_id: selectedDocument.customer.customer_id,
        customer_name: selectedDocument.customer.customer_id,
        go_to: selectedDocument.go_to,
        comment: selectedDocument.comment || '',
        measured_thing: selectedDocument.measured_thing,
        weight_before: selectedDocument.weight_before,
        weight_after: selectedDocument.weight_after,
    } : {
        entry_time: '',
        exit_time: '',
        date: initialDate,
        created_by_user: user ? user.user_id : '1',
        driver_id: '',
        driver_name: '',
        vehicle_id: '',
        vehicle_name: '',
        trailer_id: '',
        trailer_name: '',
        material_id: '',
        material_name: '',
        customer_id: '',
        customer_name: '',
        go_to: '',
        comment: '',
        measured_thing: 'truck',
        weight_before: '',
        weight_after: '',
    };

    const changeStatus = () => {
        if (selectedDocument && onUpdate) {
            const updateData = {
                ...initialValues,
                id: selectedDocument.document_id,
                status: !selectedDocument?.status
            }
            onUpdate(updateData as UpdateDocument, false)
        }
    }

    const handleDelete = () => {
        if (selectedDocument && onDelete) {
            onDelete(selectedDocument.document_id);
        }
    }

    const handlePrint = () => {
        if (selectedDocument) {
            const printContents = document.getElementById("printableArea")!.innerHTML;
            const root = document.getElementById("root");
            const documentDateTime = dayjs(`${selectedDocument.date} ${selectedDocument.exit_time}`);
            const now = dayjs();

            const printDiv = document.createElement("div");

            const addBadge = (parentDiv: any, badgeText: string) => {
                const badge = document.createElement("div");
                badge.style.position = 'absolute';
                badge.style.top = '10px';
                badge.style.left = '10px';
                badge.style.color = 'red';
                badge.style.fontWeight = 'bold';
                badge.style.padding = '5px';
                badge.style.borderRadius = '5px';
                badge.textContent = badgeText;
                parentDiv.appendChild(badge);
            };

            const pageBreakStyle = document.createElement('style');
            pageBreakStyle.type = 'text/css';
            pageBreakStyle.media = 'print';
            pageBreakStyle.innerHTML = `
            .page-break { page-break-before: always; }
            .printable-area { position: relative; }
        `;
            document.head.appendChild(pageBreakStyle);

            if (!selectedDocument.status) {
                const canceledCopy = document.createElement("div");
                canceledCopy.className = "page-break printable-area";
                canceledCopy.innerHTML = `${printContents}`;
                addBadge(canceledCopy, "מבוטל");

                printDiv.appendChild(canceledCopy);
            } else {
                if (selectedDocument.printed) {
                    const copy = document.createElement("div");
                    copy.className = "page-break printable-area";
                    copy.innerHTML = `${printContents}`;
                    addBadge(copy, "העתק");

                    printDiv.appendChild(copy);
                } else {
                    if (now.diff(documentDateTime, 'minute') <= 10) {
                        const originalCopy = document.createElement("div");
                        originalCopy.className = "page-break printable-area";
                        originalCopy.innerHTML = `${printContents}`;
                        addBadge(originalCopy, "מקור");

                        const copy1 = document.createElement("div");
                        copy1.className = "page-break printable-area";
                        copy1.innerHTML = `${printContents}`;
                        addBadge(copy1, "העתק");

                        printDiv.appendChild(originalCopy);
                        printDiv.appendChild(copy1);
                    } else {
                        const copy2 = document.createElement("div");
                        copy2.className = "page-break printable-area";
                        copy2.innerHTML = `${printContents}`;
                        addBadge(copy2, "העתק");

                        printDiv.appendChild(copy2);
                    }

                    if (selectedDocument.document_id && onUpdate && !selectedDocument.printed && selectedDocument.status) {
                        const updateData = {
                            ...initialValues,
                            id: selectedDocument.document_id,
                            printed: true
                        };
                        onUpdate(updateData as UpdateDocument, false);
                    }
                }
            }

            if (root) {
                root.style.display = 'none';
            }

            document.body.appendChild(printDiv);

            window.print();

            document.body.removeChild(printDiv);

            if (root) {
                root.style.display = 'block';
            }

            document.head.removeChild(pageBreakStyle);
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>

            <Paper elevation={onClose ? 0 : 2} sx={{
                width: onClose ? '100%' : '70%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                mb: 2,
                padding: '1rem',
            }}>
                {
                    selectedDocument && <A5Box hide document={selectedDocument} />
                }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (selectedDocument?.document_id && onUpdate) {
                            // let data = {
                            //     ...values,
                            //     id: selectedDocument.document_id
                            // }
                            // onUpdate(data as UpdateDocument)
                        } else {
                            console.log(values)
                            onCreate(values as CreateDocument);
                        }
                    }}
                    enableReinitialize
                >

                    {({ errors, touched, handleChange, values, setFieldValue, isValid, resetForm }) => (
                        <Form>
                            <Grid container rowSpacing={1} spacing={2}>
                                {selectedDocument && (
                                    <Grid item xs={12} sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <img
                                            src={selectedDocument.qr_code}
                                            alt="QR Code"
                                            width={100}
                                            height={100}
                                            style={{
                                                objectFit: 'contain',
                                                border: '1px solid #000',
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={4}>
                                    <Field
                                        as={TextField}
                                        name="date"
                                        label={t('date')}
                                        type="date"
                                        fullWidth
                                        margin="normal"
                                        required
                                        error={touched.date && Boolean(errors.date)}
                                        helperText={touched.date && errors.date}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={fieldStyles}
                                        value={values.date}
                                        onChange={(event: any) => { setFieldValue('date', event.target.value); }}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        value={values.entry_time}
                                        onChange={(name: string, value: string) => {setFieldValue(name, value)}}
                                        component={LiveClock}
                                        field_name={'entry_time'}
                                        error={touched.entry_time && Boolean(errors.entry_time)}
                                        helperText={touched.entry_time && errors.entry_time}
                                        label={t('entry_time')}
                                        disable_auto_time={selectedDocument ? true : false}
                                        refresh={refresh}
                                        
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        value={values.exit_time}
                                        onChange={(name: string, value: string) => {setFieldValue(name, value)}}
                                        component={LiveClock}
                                        field_name={'exit_time'}
                                        error={touched.exit_time && Boolean(errors.exit_time)}
                                        helperText={touched.exit_time && errors.exit_time}
                                        label={t('exit_time')}
                                        disable_auto_time={selectedDocument ? true : false}
                                        refresh={refresh}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={customers}
                                                getOptionLabel={(option) => String(option.customer_id)}
                                                value={customers.find(customer => customer.customer_id === values.customer_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('customer_id', newValue?.customer_id || '');
                                                    setFieldValue('customer_name', newValue?.name || '');
                                                    if (values.driver_id === '' && values.driver_name === '') {
                                                        setFieldValue('driver_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.driver?.driver_id || '');
                                                        setFieldValue('driver_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.driver?.name || '');
                                                    }
                                                    if (values.vehicle_id === '' && values.vehicle_name === '') {
                                                        setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.vehicle_id || '');
                                                        setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.name || '');
                                                        setFieldValue('weight_before', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.weight || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '') {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.trailer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('customerID')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.customer_id && Boolean(errors.customer_id)}
                                                        helperText={touched.customer_id && errors.customer_id}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                options={customers}
                                                getOptionLabel={(option) => String(option.name)}
                                                value={customers.find(customer => customer.customer_id === values.customer_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('customer_id', newValue?.customer_id || '');
                                                    setFieldValue('customer_name', newValue?.name || '');
                                                    if (values.driver_id === '' && values.driver_name === '') {
                                                        setFieldValue('driver_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.driver?.driver_id || '');
                                                        setFieldValue('driver_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.driver?.name || '');
                                                    }
                                                    if (values.vehicle_id === '' && values.vehicle_name === '') {
                                                        setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.vehicle_id || '');
                                                        setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.name || '');
                                                        setFieldValue('weight_before', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.weight || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '') {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.customer?.customer_id === newValue?.customer_id)?.trailer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('customerName')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.customer_name && Boolean(errors.customer_name)}
                                                        helperText={touched.customer_name && errors.customer_name}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={drivers}
                                                getOptionLabel={(option) => String(option.driver_id)}
                                                value={drivers.find(driver => driver.driver_id === values.driver_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('driver_id', newValue?.driver_id || '');
                                                    setFieldValue('driver_name', newValue?.name || '');
                                                    if (values.vehicle_id === '' && values.vehicle_name === '') {
                                                        setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.vehicle_id || '');
                                                        setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.name || '');
                                                        setFieldValue('weight_before', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.weight || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '') {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.trailer?.name || '');
                                                    }
                                                    if (values.customer_id === '' && values.customer_name === '') {
                                                        setFieldValue('customer_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.customer?.customer_id || '');
                                                        setFieldValue('customer_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.customer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('driverID')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.driver_id && Boolean(errors.driver_id)}
                                                        helperText={touched.driver_id && errors.driver_id}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                options={drivers}
                                                getOptionLabel={(option) => String(option.name)}
                                                value={drivers.find(driver => driver.driver_id === values.driver_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('driver_id', newValue?.driver_id || '');
                                                    setFieldValue('driver_name', newValue?.name || '');
                                                    if (values.vehicle_id === '' && values.vehicle_name === '') {
                                                        setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.vehicle_id || '');
                                                        setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.name || '');
                                                        setFieldValue('weight_before', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.weight || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '') {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.trailer?.name || '');
                                                    }
                                                    if (values.customer_id === '' && values.customer_name === '') {
                                                        setFieldValue('customer_id', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.customer?.customer_id || '');
                                                        setFieldValue('customer_name', vehicles.find(vehicle => vehicle.driver?.driver_id === newValue?.driver_id)?.customer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('driverName')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.driver_name && Boolean(errors.driver_name)}
                                                        helperText={touched.driver_name && errors.driver_name}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <Autocomplete
                                                options={trailers}
                                                getOptionLabel={(option) => String(option.trailer_id)}
                                                value={trailers.find(trailer => trailer.trailer_id === values.trailer_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('trailer_id', newValue?.trailer_id || '');
                                                    setFieldValue('trailer_name', newValue?.name || '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('trailerID')}
                                                        margin="normal"
                                                        fullWidth
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={8}>
                                            <Autocomplete
                                                options={trailers}
                                                getOptionLabel={(option) => `${option.name} || ${option.trailer_number}`}
                                                value={trailers.find(trailer => trailer.trailer_id === values.trailer_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('trailer_id', newValue?.trailer_id || '');
                                                    setFieldValue('trailer_name', newValue?.name || '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('trailerName')}
                                                        margin="normal"
                                                        fullWidth
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4}>
                                            <Autocomplete
                                                options={vehicles}
                                                getOptionLabel={(option) => String(option.vehicle_id)}
                                                value={vehicles.find(vehicle => vehicle.vehicle_id === values.vehicle_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.vehicle_id || '');
                                                    setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.name || '');
                                                    setFieldValue('weight_before', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.weight || '');
                                                    if (values.driver_id === '' && values.driver_name === '') {
                                                        setFieldValue('driver_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.driver?.driver_id || '');
                                                        setFieldValue('driver_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.driver?.name || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '' && newValue?.trailer) {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.trailer?.trailer_number || '');
                                                    }
                                                    if (values.customer_id === '' && values.customer_name === '' && newValue?.customer) {
                                                        setFieldValue('customer_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.customer?.customer_id || '');
                                                        setFieldValue('customer_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.customer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('vehicleID')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.vehicle_id && Boolean(errors.vehicle_id)}
                                                        helperText={touched.vehicle_id && errors.vehicle_id}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={8}>
                                            <Autocomplete
                                                options={vehicles}
                                                getOptionLabel={(option) => String(option.name) + ' || ' + String(option.vehicle_number)}
                                                value={vehicles.find(vehicle => vehicle.vehicle_id === values.vehicle_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('vehicle_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.vehicle_id || '');
                                                    setFieldValue('vehicle_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.name || '');
                                                    setFieldValue('weight_before', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.weight || '');
                                                    if (values.driver_id === '' && values.driver_name === '') {
                                                        setFieldValue('driver_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.driver?.driver_id || '');
                                                        setFieldValue('driver_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.driver?.name || '');
                                                    }
                                                    if (values.trailer_id === '' && values.trailer_name === '' && newValue?.trailer) {
                                                        setFieldValue('trailer_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.trailer?.trailer_id || '');
                                                        setFieldValue('trailer_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.trailer?.trailer_number || '');
                                                    }
                                                    if (values.customer_id === '' && values.customer_name === '' && newValue?.customer) {
                                                        setFieldValue('customer_id', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.customer?.customer_id || '');
                                                        setFieldValue('customer_name', vehicles.find(vehicle => vehicle.vehicle_id === newValue?.vehicle_id)?.customer?.name || '');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('vehicleName')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.vehicle_name && Boolean(errors.vehicle_name)}
                                                        helperText={touched.vehicle_name && errors.vehicle_name}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                options={materials}
                                                getOptionLabel={(option) => String(option.material_id)}
                                                value={materials.find(material => material.material_id === values.material_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('material_id', newValue?.material_id || '');
                                                    setFieldValue('material_name', newValue?.name || '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('materialID')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.material_id && Boolean(errors.material_id)}
                                                        helperText={touched.material_id && errors.material_id}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                options={materials}
                                                getOptionLabel={(option) => String(option.name)}
                                                value={materials.find(material => material.material_id === values.material_id) || null}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('material_id', newValue?.material_id || '');
                                                    setFieldValue('material_name', newValue?.name || '');
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('materialName')}
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        error={touched.material_name && Boolean(errors.material_name)}
                                                        helperText={touched.material_name && errors.material_name}
                                                        sx={autocompleteStyles}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={8}>
                                        <Field
                                            as={TextField}
                                            name="go_to"
                                            label={t('address')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.go_to && Boolean(errors.go_to)}
                                            value={values.go_to}
                                            onChange={handleChange}
                                            sx={fieldStyles}
                                        />
                                    </Grid>
                                    <Grid container gap={1}>
                                        <Grid item xs={2}>
                                            <Field
                                                as={TextField}
                                                name="weight_before"
                                                label={t('weightBefore')}
                                                type="text"
                                                fullWidth
                                                margin="normal"
                                                required
                                                error={touched.weight_before && Boolean(errors.weight_before)}
                                                value={Number(values.weight_before) || ''}
                                                onChange={handleChange}
                                                sx={fieldStyles}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Field
                                                as={TextField}
                                                name="weight_after"
                                                label={t('weightAfter')}
                                                type="text"
                                                fullWidth
                                                margin="normal"
                                                required
                                                error={values.weight_after && touched.weight_after && Boolean(errors.weight_after)}
                                                value={Number(values.weight_after) || ''}
                                                onChange={handleChange}
                                                sx={fieldStyles}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Field
                                                as={TextField}
                                                name="Result"
                                                label={t('result')}
                                                type="text"
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={Number(values.weight_after) - Number(values.weight_before)}
                                                sx={fieldStyles}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid xs={10}>
                                        <Field
                                            as={TextField}
                                            name="comment"
                                            label={t('comment')}
                                            fullWidth
                                            margin="normal"
                                            multiline
                                            minRows={4}
                                            maxRows={5}
                                            error={touched.comment && Boolean(errors.comment)}
                                            helperText={touched.comment && errors.comment}
                                            value={values.comment}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" margin="normal" required error={touched.measured_thing && Boolean(errors.measured_thing)}>
                                        <FormLabel component="legend" sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('measuredThing')}</FormLabel>
                                        <FormGroup sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                            {
                                                ['truck', 'trailer_and_truck', 'trailer'].map((option) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                as={Checkbox}
                                                                name="measured_thing"
                                                                value={option}
                                                                checked={values.measured_thing === option}
                                                                onChange={(event: any) => {
                                                                    if (event.target.checked) {
                                                                        setFieldValue('measured_thing', event.target.value);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label={t(option)}
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                        {touched.measured_thing && errors.measured_thing && (
                                            <FormHelperText>{errors.measured_thing}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {requesting ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }} gap={2}>
                                        {
                                            screen && screen !== 'update' && (
                                                <Button
                                                    type="button"
                                                    color="warning"
                                                    variant="contained"
                                                    disabled={requesting}
                                                    onClick={() => {
                                                        resetForm();
                                                        triggerRefresh();
                                                        if (handleSelectDocument) {
                                                            if (selectedDocument?.document_id && onUpdate && selectedDocument.status && !selectedDocument.printed) {
                                                                const updateData = {
                                                                    ...initialValues,
                                                                    id: selectedDocument.document_id,
                                                                    printed: true
                                                                }
                                                                onUpdate(updateData as UpdateDocument, true);
                                                            }
                                                            handleSelectDocument(null);
                                                        }
                                    
                                                    }}
                                                    startIcon={<Refresh />}
                                                >
                                                    {t('reset')}
                                                </Button>
                                            )
                                        }

                                        {!selectedDocument && <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={requesting}
                                            startIcon={<Create />}
                                        >
                                            {t('save')}
                                        </Button>}

                                        {selectedDocument && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={changeStatus}
                                                    disabled={requesting || !selectedDocument.status}
                                                    startIcon={<Cancel />}
                                                >
                                                    {t('cancel')}
                                                </Button>
                                                {
                                                    /* <Button
                                                    type="button"
                                                    color="error"
                                                    variant="contained"
                                                    disabled={requesting}
                                                    onClick={handleDelete}
                                                    startIcon={<Delete />}>
                                                        {t('delete')}
                                                    </Button> 
                                                    */
                                                }
                                                <Button
                                                    type="button"
                                                    color="info"
                                                    variant="contained"
                                                    disabled={requesting}
                                                    onClick={handlePrint}
                                                    startIcon={<Print />}
                                                >
                                                    {t('print')}
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                )}
                            </Grid>

                        </Form>
                    )}
                </Formik>
            </Paper>
        </LocalizationProvider>
    );
};

export default DocumentForm;
