import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateCustomer, UpdateCustomer} from 'types/customer';
import { 
    get_all_customer_service, 
    get_customer_by_id_service, 
    create_customer_service,
    update_customer_service,
    delete_customer_service } from 'services/customer'

export const getAllCustomerAction = createAsyncThunk(
    'customer/getAllCustomer',
    async (_, thunkAPI) => {
        try {
            return await get_all_customer_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getCustomerByIDAction = createAsyncThunk(
    'customer/getAllCustomer',
    async (id:string, thunkAPI) => {
        try {
            return await get_customer_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createCustomerAction = createAsyncThunk(
    'customer/createCustomer',
    async (data: CreateCustomer, thunkAPI) => {
        try {
            return await create_customer_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateCustomerAction = createAsyncThunk(
    'customer/updateCustomer',
    async (data: UpdateCustomer, thunkAPI) => {
        try {
            return await update_customer_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteCustomerAction = createAsyncThunk(
    'customer/deleteCustomer',
    async (id: string, thunkAPI) => {
        try {
            return await delete_customer_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
