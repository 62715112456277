import { useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/index';
import { getDocumentByUUIDAction } from 'store/actions/document';
import PreviewDocumentDialog from 'components/document/previewDocumentDialog';

export interface ICertificateDocumentPageProps {}

export default function CertificateDocumentPage(props: ICertificateDocumentPageProps) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { previewDocument, documentsIsLoading } = useAppSelector(state => state.store.document);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid');
    if (uuid) {
      dispatch(getDocumentByUUIDAction(uuid));
    }
  }, [location.search, dispatch]);

  return (
    <Box sx={{ padding: 3, height: '100vh', position: 'relative' }}>
      {documentsIsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        previewDocument && (
          <>
            <PreviewDocumentDialog document={previewDocument} />
          </>
        )
      )}
    </Box>
  );
}