import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import { 
    getAllTrailerAction,
    createTrailerAction,
    updateTrailerAction,
    deleteTrailerAction
} from 'store/actions/trailer';
import TrailerTable from './trailerTable';
import { CreateTrailer, UpdateTrailer, Trailer as TrailerType } from 'types/trailer';
import NewTrailerForm from './newTrailerDialog';
import DeleteTrailerDialog from './deleteTrailerDialog';
import UpdateTrailerDialog from './updateTrailerDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface ITrailerProps {}

export default function TrailerView(props: ITrailerProps) {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [selectedTrailer, setSelectedTrailer] = React.useState<TrailerType | null>(null);
    const { trailers, trailersIsLoading, trailersError, trailerRequest } = useAppSelector(state => state.store.trailer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(getAllTrailerAction());
    }, [dispatch]);

    const onCreate = (data: CreateTrailer) => {
        dispatch(createTrailerAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('trailerCreatedSuccessfully'));
            }
        });
    };

    const onUpdate = (data: UpdateTrailer) => {
        dispatch(updateTrailerAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenUpdateDialog(false);
                toast.success(t('trailerUpdatedSuccessfully'));
            }
        });
    };

    const onDelete = (id: string) => {
        dispatch(deleteTrailerAction(id)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenDeleteDialog(false);
                toast.success(t('trailerDeletedSuccessfully'));
            }
        });
    };

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    };

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    };

    const handleUpdateDialogOpen = (trailer: TrailerType) => {
        setSelectedTrailer(trailer);
        setOpenUpdateDialog(true);
    };

    const handleDeleteDialogOpen = (trailer: TrailerType) => {
        setSelectedTrailer(trailer);
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="body1"
                        mb={1}
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            marginTop: '1rem',
                        }}
                    >
                        {t('trailers')}
                    </Typography>
                    <Button 
                        disabled={trailersIsLoading || trailerRequest}
                        variant='contained' 
                        color='secondary' 
                        onClick={handleCreateDialogOpen}>
                        {t('addTrailer')}
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <TrailerTable
                        openCreateDialog={openCreateDialog}
                        openUpdateDialog={openUpdateDialog}
                        isLoading={trailersIsLoading || trailerRequest}
                        trailers={trailers}
                        selectedTrailer={selectedTrailer}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        handleUpdateDialogClose={handleUpdateDialogClose}
                        handleDeleteDialogClose={handleDeleteDialogClose}
                        handleUpdateDialogOpen={handleUpdateDialogOpen}
                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                </Grid>
            </Grid>
            <NewTrailerForm open={openCreateDialog} onCreate={onCreate} onClose={handleCreateDialogClose} requesting={trailerRequest} />
            <DeleteTrailerDialog selectedTrailer={selectedTrailer} open={openDeleteDialog} onDelete={onDelete} onClose={handleDeleteDialogClose} requesting={trailerRequest} />
            <UpdateTrailerDialog selectedTrailer={selectedTrailer} open={openUpdateDialog} onUpdate={onUpdate} onClose={handleUpdateDialogClose} requesting={trailerRequest} />
        </>
    );
}
