import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {routesArray} from 'routers'
import { useAppSelector } from 'store';

export const Navlist = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { user } = useAppSelector((state) => state.store.auth);
  
    return (
      <>
        {routesArray.map((route, index) => {
          if (route.nav) {
            const isActive = location.pathname === route.path;
  
            const shouldRender =
              (user?.is_superuser && route.requires_superuser) || !route.requires_superuser;
  
            return shouldRender ? (
              <ListItem
                key={index}
                button
                component={Link}
                to={route.path}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(39, 133, 245, 0.33)',
                  },
                  ...(isActive && {
                    color: 'primary.main',
                    fontWeight: 'bold',
                  }),
                  borderBottom: '1px solid #F7F5F4',
                }}
              >
                <ListItemIcon>
                  {React.cloneElement(route.icon!, {
                    color: isActive ? 'primary' : 'inherit',
                  })}
                </ListItemIcon>
                <Typography
                  variant="h6"
                  sx={{
                    color: isActive ? 'primary.main' : 'inherit',
                    fontWeight: isActive ? 'bold' : '800',
                    fontSize: isActive ? '1.3rem' : '1rem',
                  }}
                >
                  {t(route.label)}
                </Typography>
              </ListItem>
            ) : null;
          }
  
          return null;
        })}
      </>
    );
  };