import React from 'react';
import { Box, CardHeader, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DocumentForm from './documentForm';
import { CreateDocument } from 'types/document';

interface NewDocumentDialogProps {
    onCreate: (values: CreateDocument) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const modalStyles = {
    position: 'absolute' as const,
    width:'80%',
    maxWidth: '90%',
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const NewDocumentDialog: React.FC<NewDocumentDialogProps> = ({ onCreate, onClose, requesting, open }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}>
                    <CardHeader
                        subheader={t('fillFormAddDocument')}
                        title={t('addNewDocument')}
                    />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DocumentForm 
                    screen='create'
                    onCreate={onCreate} 
                    requesting={requesting} 
                    onClose={onClose} />
            </Box>
        </Modal>
    );
};

export default NewDocumentDialog;
