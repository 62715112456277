import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateDocument, UpdateDocument} from 'types/document';
import { 
    get_all_document_service, 
    get_document_pagination_service,
    get_document_by_id_service, 
    get_document_by_uuid_service,
    create_document_service,
    update_document_service,
    delete_document_service } from 'services/document'
import { pagination } from 'types/pagination';

export const getAllDocumentAction = createAsyncThunk(
    'Document/getAllDocument',
    async (_, thunkAPI) => {
        try {
            return await get_all_document_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
export const getDocumentPaginationAction = createAsyncThunk(
    'Document/getDocumentPagination',
    async (data:any, thunkAPI) => {
        try {
            return await get_document_pagination_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getDocumentByUUIDAction = createAsyncThunk(
    'Document/getAllDocumentUUID',
    async (uuid:string, thunkAPI) => {
        try {
            return await get_document_by_uuid_service(uuid);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getDocumentByIDAction = createAsyncThunk(
    'Document/getAllDocument',
    async (id:string, thunkAPI) => {
        try {
            return await get_document_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createDocumentAction = createAsyncThunk(
    'Document/createDocument',
    async (data: CreateDocument, thunkAPI) => {
        try {
            return await create_document_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateDocumentAction = createAsyncThunk(
    'Document/updateDocument',
    async (data: UpdateDocument, thunkAPI) => {
        try {
            return await update_document_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteDocumentAction = createAsyncThunk(
    'Document/deleteDocument',
    async (id: string, thunkAPI) => {
        try {
            return await delete_document_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
