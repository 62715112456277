import React from 'react';
import { Box, Paper, Skeleton } from '@mui/material';

const FormLoadingSkeleton: React.FC = () => {
    return (
        <Paper elevation={2} sx={{
            width: '70%' ,
            padding: 2,
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
        }}>
            {[...Array(10)].map((_, index) => (
                <Box display="flex" gap={2} key={index}>
                    <Skeleton variant="rectangular" width={'30%'} height={56} sx={{
                        borderRadius: '10px',
                    }} />
                    <Skeleton variant="rectangular" width={'70%'} height={56} sx={{
                        borderRadius: '10px',
                    }}/>
                </Box>
            ))}
        </Paper>
    );
};

export default FormLoadingSkeleton;
