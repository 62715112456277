import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateTrailer, UpdateTrailer} from 'types/trailer';
import { 
    get_all_trailer_service, 
    get_trailer_by_id_service, 
    create_trailer_service,
    update_trailer_service,
    delete_trailer_service } from 'services/trailer'

export const getAllTrailerAction = createAsyncThunk(
    'trailer/getAllTrailer',
    async (_, thunkAPI) => {
        try {
            return await get_all_trailer_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getTrailerByIDAction = createAsyncThunk(
    'trailer/getAllTrailer',
    async (id:string, thunkAPI) => {
        try {
            return await get_trailer_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createTrailerAction = createAsyncThunk(
    'trailer/createTrailer',
    async (data: CreateTrailer, thunkAPI) => {
        try {
            return await create_trailer_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateTrailerAction = createAsyncThunk(
    'trailer/updateTrailer',
    async (data: UpdateTrailer, thunkAPI) => {
        try {
            return await update_trailer_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteTrailerAction = createAsyncThunk(
    'trailer/deleteTrailer',
    async (id: string, thunkAPI) => {
        try {
            return await delete_trailer_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
