import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton, Autocomplete } from '@mui/material';
import * as Yup from 'yup';
import { CreateVehicle } from 'types/vehicle';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'store/index';
import { useTranslation } from 'react-i18next';

interface NewVehicleFormProps {
    onCreate: (values: CreateVehicle) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const NewVehicleForm: React.FC<NewVehicleFormProps> = ({ onCreate, onClose, requesting, open }) => {
    const { t } = useTranslation();
    const { drivers, driversIsLoading } = useAppSelector(state => state.store.driver);
    const { trailers, trailersIsLoading } = useAppSelector(state => state.store.trailer);
    const { customers, customersIsLoading } = useAppSelector(state => state.store.customer);

    const validationSchema = Yup.object().shape({
        name: Yup.string(),
        vehicle_number: Yup.string().required(t('vehicleNumberRequired')),
        driver: Yup.string(),
    });

    const initialValues: CreateVehicle = {
        name: '',
        vehicle_number: '',
        weight:'',
        driver: '',
        trailer: '',
        customer: '',
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
            <Box sx={modalStyles}>
                <Box sx={{ alignSelf: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <CardHeader
                    subheader={t('fillFormAddVehicle')}
                    title={t('addNewVehicle')}
                />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onCreate(values);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="name"
                                            label={t('vehicleName')}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="vehicle_number"
                                            label={t('vehicleNumber')}
                                            fullWidth
                                            margin="normal"
                                            required
                                            error={touched.vehicle_number && Boolean(errors.vehicle_number)}
                                            helperText={touched.vehicle_number && String(t(errors.vehicle_number!))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            as={TextField}
                                            name="weight"
                                            label={t('vehicleWeight')}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {trailersIsLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        options={trailers}
                                                        getOptionLabel={(option) => option.trailer_number}
                                                        value={trailers.find(trailer => trailer.trailer_id === initialValues.trailer)}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('trailer', newValue?.trailer_id || '');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('trailerName')}
                                                                margin="normal"
                                                                fullWidth
                                                                error={touched.trailer && Boolean(errors.trailer)}
                                                                helperText={touched.trailer && String(t(errors.trailer!))}
                                                                sx={autocompleteStyles}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {driversIsLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        options={drivers}
                                                        getOptionLabel={(option) => option.name}
                                                        value={drivers.find(driver => driver.driver_id === initialValues.driver)}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('driver', newValue?.driver_id || '');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('driverName')}
                                                                margin="normal"
                                                                fullWidth
                                                                error={touched.driver && Boolean(errors.driver)}
                                                                helperText={touched.driver && String(t(errors.driver!))}
                                                                sx={autocompleteStyles}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {customersIsLoading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        options={customers}
                                                        getOptionLabel={(option) => option.name}
                                                        value={customers.find(customer => customer.customer_id === initialValues.customer)}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('customer', newValue?.customer_id || '');
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('customerName')}
                                                                margin="normal"
                                                                fullWidth
                                                                error={touched.customer && Boolean(errors.customer)}
                                                                helperText={touched.customer && String(t(errors.customer!))}
                                                                sx={autocompleteStyles}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                                    <Button onClick={onClose} color="secondary" variant="outlined" style={{ marginRight: 8 }}>
                                        {t('cancel')}
                                    </Button>
                                    <Button type="submit" color="primary" variant="contained" disabled={requesting}>
                                        {requesting ? <CircularProgress size={24} sx={{ color: 'text.primaryChannel' }} /> : t('submit')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Modal>
    );
};

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const autocompleteStyles = {
    '& .MuiFormControl-root': {
        width: '100%',
    },
    '& .MuiInputBase-root': {
        height: '56px',
    },
    '& .MuiAutocomplete-inputRoot': {
        padding: '0px 8px',
    },
};

export default NewVehicleForm;
