
import { UpdateCustomer, CreateCustomer } from 'types/customer'
import axiosInstance from 'services/axiosInstance'


export const get_all_customer_service = async () => {
  const response = await axiosInstance.get('/customer/');
  return response;
};


export const get_customer_by_id_service = async (id:string) => {
    const response = await axiosInstance.get(`/customer/${id}/`);
    return response;
  };

export const create_customer_service = async (data: CreateCustomer) => {
    const response = await axiosInstance.post('/customer/', data);
    return response;
    }

export const update_customer_service = async (data:UpdateCustomer) => {
  const response = await axiosInstance.put(`/customer/${data.id}/`, {
    name: data.name,
    address: data.address,
    phone: data.phone,
    second_phone: data.second_phone
  });
  return response;
}

export const delete_customer_service = async (id:string) => {
  const response = await axiosInstance.delete(`/customer/${id}/`);
  return response;
}