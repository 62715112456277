import React, { useState, useMemo, useEffect } from 'react';
import { Document, UpdateDocument } from 'types/document';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_AR } from 'material-react-table/locales/ar';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { IconButton, Tooltip, Box, Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import i18n from 'locale/i18n';
import 'dayjs/locale/he';
import 'dayjs/locale/ar';
import { useAppSelector } from 'store/index';

import { download_documents_excel_service } from 'services/document';

export interface IDocumentTableProps {
  getData: (parms?: any) => void;
  openCreateDialog: boolean;
  openUpdateDialog: boolean;
  openPreviewDialog: boolean;
  isLoading: boolean;
  documents: Document[];
  selectedDocument: Document | null;
  onUpdate: (data: UpdateDocument) => void;
  onDelete: (id: string) => void;
  handleUpdateDialogClose: () => void;
  handleDeleteDialogClose: () => void;
  handlePreviewDialogClose: () => void;
  handleUpdateDialogOpen: (Document: Document) => void;
  handleDeleteDialogOpen: (Document: Document) => void;
  handleOpenPreviewDialog: (Document: Document) => void;
}

export default function DocumentTable(props: IDocumentTableProps) {
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { documentsIsLoading, documentsError, documentRequest, paginationData } = useAppSelector((state) => state.store.document);
  const { drivers, driversIsLoading } = useAppSelector((state) => state.store.driver);
  const { customers, customersIsLoading } = useAppSelector((state) => state.store.customer);
  const { vehicles, vehiclesIsLoading } = useAppSelector((state) => state.store.vehicle);
  const { trailers, trailersIsLoading } = useAppSelector((state) => state.store.trailer);
  const { materials, materialsIsLoading } = useAppSelector((state) => state.store.material);

  useEffect(() => {
    props.getData({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize, columnFilters, globalFilter, sorting });
  }, [pagination, columnFilters, globalFilter, sorting]);

  const handleDownload = async () => {
    const data = {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      columnFilters,
      globalFilter,
      sorting,
    };
    try {
      const response = await download_documents_excel_service(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download documents:', error);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Document>[]>(
    () => [
      {
        accessorKey: 'document_id',
        header: t('ID'),
        size: 20,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'date',
        accessorFn: (originalRow) => dayjs(originalRow.date).format('DD/MM/YYYY'),
        header: t('date'),
        size: 50,
        filterVariant: 'date-range',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'entry_time',
        header: t('entry_time'),
        size: 50,
        filterVariant: 'time',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'ar' ? 'ar' : 'he'}>
            <TimePicker
              ampm={false}
              views={['hours']}
              onChange={(newValue: any) => {
                column.setFilterValue(newValue ? dayjs(newValue).format('HH') : undefined);
              }}
              value={(column: any) => column.filterValue ? dayjs(column.filterValue, 'HH').toDate() : null}
              slotProps={{
                textField: {
                  placeholder: t('entry_time'),
                  variant: "standard",
                  size: 'small',
                  fullWidth: true,
                  required: false,
                  error: false,
                  style: { padding: '.2rem' },
                }
              }}
            />
          </LocalizationProvider>
        ),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'exit_time',
        header: t('exit_time'),
        size: 50,
        filterVariant: 'time',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'ar' ? 'ar' : 'he'}>
            <TimePicker
              ampm={false}
              views={['hours']}
              onChange={(newValue: any) => {
                column.setFilterValue(newValue ? dayjs(newValue).format('HH') : undefined);
              }}
              value={(column: any) => column.filterValue ? dayjs(column.filterValue, 'HH').toDate() : null}
              slotProps={{
                textField: {
                  variant: "standard",
                  size: 'small',
                  placeholder: t('exit_time'),
                  fullWidth: true,
                  required: false,
                  error: false,
                  style: { padding: '.2rem' },
                }
              }}
            />
          </LocalizationProvider>
        ),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'customer.name',
        header: t('customerName'),
        size: 50,
        filterSelectOptions: customers.map((customer) => ({ label: customer.name, value: customer.name })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'driver.name',
        header: t('driverName'),
        size: 50,
        filterSelectOptions: drivers.map((driver) => ({ label: driver.name, value: driver.name })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'vehicle.vehicle_number',
        header: t('vehicleNumber'),
        size: 50,
        filterSelectOptions: vehicles.map((vehicle) => ({ label: vehicle.vehicle_number, value: vehicle.vehicle_number })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'trailer.trailer_number',
        header: t('trailerNumber'),
        size: 50,
        filterSelectOptions: trailers.map((trailer) => ({ label: trailer.trailer_number, value: trailer.trailer_number })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'material.name',
        header: t('materialName'),
        size: 50,
        filterSelectOptions: materials.map((material) => ({ label: material.name, value: material.name })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'go_to',
        header: t('address'),
        size: 50,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'measured_thing',
        header: t('measuredThing'),
        size: 50,
        Cell: ({ cell }: any) => <span>{t(cell.getValue())}</span>,
        filterOptions: ['truck', 'trailer', 'trailer_and_truck'].map((option) => ({ label: t(option), value: option })),
        filterVariant: 'multi-select',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      // {
      //   accessorKey: 'weight_before',
      //   header: t('weightBefore'),
      //   size: 50,
      //   filterVariant: 'text',
      //   muiTableHeadCellProps: {
      //     align: 'center',
      //   },
      //   muiTableBodyCellProps: {
      //     align: 'center',
      //   },
      //   muiTableFooterCellProps: {
      //     align: 'center',
      //   },
      // },
      // {
      //   accessorKey: 'weight_after',
      //   header: t('weightAfter'),
      //   size: 50,
      //   filterVariant: 'text',
      //   muiTableHeadCellProps: {
      //     align: 'center',
      //   },
      //   muiTableBodyCellProps: {
      //     align: 'center',
      //   },
      //   muiTableFooterCellProps: {
      //     align: 'center',
      //   },
      // },
      {
        accessorKey: 'weight_difference',
        header: t('result'),
        size: 50,
        accessorFn: (originalRow) => Number(originalRow.weight_after) - Number(originalRow.weight_before),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'status',
        header: t('status'),
        size: 50,
        Cell: ({ cell }: any) => <span>{t(cell.getValue())}</span>,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'comment',
        header: t('comment'),
        size: 50,
        Cell: ({ cell }: any) => (
          <span
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '10rem',
            }}
          >
            {t(cell.getValue())}
          </span>
        ),
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
      {
        header: t('actions'),
        Cell: ({ row }: any) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton
                color="primary"
                onClick={() => { props.handleUpdateDialogOpen(row.original); }}>
                <EditIcon />
              </IconButton>
            </Box>
          );
        },
        size: 150,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
      },
    ],
    [t]
  );

  const table = useMaterialReactTable({
    columns,
    data: paginationData?.results || [],
    enableStickyHeader: true,
    columnResizeDirection: 'rtl',
    initialState: {
      showColumnFilters: false,
      density: 'compact',
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button onClick={handleDownload} variant='contained' color='warning' sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
          opacity: 0.9
        }}>
          <FileDownloadIcon />
        </Button>
        <Button onClick={() => props.getData({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize })} variant='contained' color='success' disabled={paginationData?.results.length === 0} sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
          opacity: 0.9,
        }}>
          <RefreshIcon />
        </Button>
      </Box>

    ),
    rowCount: paginationData?.count || 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading: documentsIsLoading,
      pagination,
      showAlertBanner: documentsError ? true : false,
      showProgressBars: documentRequest,
      sorting,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    localization: i18n.language === 'ar' ? MRT_Localization_AR : MRT_Localization_HE,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'ar' ? 'ar' : 'he'}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
}
