
import { UpdateMaterial, CreateMaterial } from 'types/material'
import axiosInstance from 'services/axiosInstance'


export const get_all_material_service = async () => {
  const response = await axiosInstance.get('/material/');
  return response;
};


export const get_material_by_id_service = async (id:string) => {
    const response = await axiosInstance.get(`/material/${id}/`);
    return response;
  };

export const create_material_service = async (data: CreateMaterial) => {
    const response = await axiosInstance.post('/material/', data);
    return response;
    }

export const update_material_service = async (data:UpdateMaterial) => {
  const response = await axiosInstance.put(`/material/${data.id}/`, {
    name: data.name,
  });
  return response;
}

export const delete_material_service = async (id:string) => {
  const response = await axiosInstance.delete(`/material/${id}/`);
  return response;
}