export const setUser = (user: any) => {
    localStorage.setItem('user-wt', JSON.stringify(user));
  };
  
  export const getUser = () => {
    const userString = localStorage.getItem('user-wt');
    return userString ? JSON.parse(userString) : null;
  };
  
  export const removeUser = () => {
    localStorage.removeItem('user-wt');
  };
  