import * as React from 'react';
import { Box } from '@mui/material';
import DriverView from 'components/driver';
export interface IDriverPageProps {
}

export default function DriverPage (props: IDriverPageProps) {
  return (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      > 
        <DriverView />
    </Box>
  );
}
