import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleState } from 'types/vehicle';
import { 
    getAllVehicleAction, 
    getVehicleByIDAction, 
    createVehicleAction,
    updateVehicleAction,
    deleteVehicleAction
} from 'store/actions/vehicle';


const initialState: VehicleState = {
    vehicles: [],
    vehiclesIsLoading: false,
    vehiclesError: '',
    vehicleRequest: false
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL Vehicle START *********** \\
    .addCase(getAllVehicleAction.pending, (state) => {
        state.vehiclesIsLoading = true;
        state.vehiclesError = '';
    })
    .addCase(getAllVehicleAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.vehicles = action.payload.data;
        state.vehiclesIsLoading = false;
    })
    .addCase(getAllVehicleAction.rejected, (state, action: PayloadAction<any>) => {
        state.vehiclesError = action.payload.message;
        state.vehiclesIsLoading = false;
    })
    // *********** GET ALL Vehicle END *********** \\
    // *********** CREATE Vehicle START *********** \\
    .addCase(createVehicleAction.pending, (state) => {
        state.vehiclesIsLoading = true;
        state.vehiclesError = '';
    })
    .addCase(createVehicleAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.vehicles.push(action.payload.data);
        state.vehiclesIsLoading = false;
    })
    .addCase(createVehicleAction.rejected, (state, action: PayloadAction<any>) => {
        state.vehiclesError = action.payload.message;
        state.vehiclesIsLoading = false;
    })
    // *********** CREATE Vehicle END *********** \\
    // *********** UPDATE Vehicle START *********** \\
    .addCase(updateVehicleAction.pending, (state) => {
        state.vehiclesIsLoading = true;
        state.vehiclesError = '';
    })
    .addCase(updateVehicleAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.vehicles.findIndex((vehicle) => vehicle.vehicle_id === action.payload.data.vehicle_id);
        state.vehicles[index] = action.payload.data;
        state.vehiclesIsLoading = false;
    })
    .addCase(updateVehicleAction.rejected, (state, action: PayloadAction<any>) => {
        state.vehiclesError = action.payload.message;
        state.vehiclesIsLoading = false;
    })
    // *********** UPDATE Vehicle END *********** \\
    // *********** DELETE Vehicle START *********** \\
    .addCase(deleteVehicleAction.pending, (state) => {
        state.vehiclesIsLoading = true;
        state.vehiclesError = '';
    })
    .addCase(deleteVehicleAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.vehicles = state.vehicles.filter((vehicle) => vehicle.vehicle_id !== action.payload.data.vehicle_id);
        state.vehiclesIsLoading = false;
    })
    .addCase(deleteVehicleAction.rejected, (state, action: PayloadAction<any>) => {
        state.vehiclesError = action.payload.message;
        state.vehiclesIsLoading = false;
    })
    // *********** DELETE Vehicle END *********** \\
  },
  
});
export const { } = vehicleSlice.actions

export default vehicleSlice.reducer;