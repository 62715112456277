
import { authActionType } from 'types/auth'
import axiosInstance from 'services/axiosInstance'


export const checkAuthService = async () => {
  const response = await axiosInstance.get('/checkAuth/');
  return response;
};


export const loginService = async (data:authActionType) => {
    const response = await axiosInstance.post('/login/', {
      username: data.username,
      password: data.password,
    });
    return response;
  };


export const logoutService = async () => {
  const response = await axiosInstance.post('/logout/');
  return response;
}