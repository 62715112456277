
import { UpdateVehicle, CreateVehicle } from 'types/vehicle'
import axiosInstance from 'services/axiosInstance'


export const get_all_vehicle_service = async () => {
  const response = await axiosInstance.get('/vehicle/');
  return response;
};


export const get_vehicle_by_id_service = async (id:string) => {
    const response = await axiosInstance.get(`/vehicle/${id}/`);
    return response;
  };

export const create_vehicle_service = async (data: CreateVehicle) => {
    const response = await axiosInstance.post('/vehicle/', data);
    return response;
    }

export const update_vehicle_service = async (data:UpdateVehicle) => {
  const response = await axiosInstance.put(`/vehicle/${data.id}/`, {
    name: data.name,
    vehicle_number: data.vehicle_number,
    weight: data.weight,
    driver: data.driver,
    trailer: data.trailer,
    customer: data.customer
  });
  return response;
}

export const delete_vehicle_service = async (id:string) => {
  const response = await axiosInstance.delete(`/vehicle/${id}/`);
  return response;
}