import * as React from 'react';
import { Customer, CreateCustomer, UpdateCustomer } from 'types/customer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { MRT_Localization_AR } from 'material-react-table/locales/ar';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Box, IconButton } from '@mui/material';
import i18n from 'locale/i18n';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export interface ICustomerTableProps {
  openCreateDialog: boolean;
  openUpdateDialog: boolean;
  isLoading: boolean;
  customers: Customer[];
  selectedCustomer: Customer | null;
  onUpdate: (data: UpdateCustomer) => void;
  onDelete: (id: string) => void;
  handleUpdateDialogClose: () => void;
  handleDeleteDialogClose: () => void;
  handleUpdateDialogOpen: (customer: Customer) => void;
  handleDeleteDialogOpen: (customer: Customer) => void;
}

export default function CustomerTable(props: ICustomerTableProps) {
  const { t } = useTranslation();

  const columns = React.useMemo<MRT_ColumnDef<Customer>[]>(
    () => [
      {
        accessorKey: 'customer_id',
        header: t('ID'),
        size: 200,
        Cell: ({ cell }: any) => cell.getValue(),
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'name',
        header: t('customerName'),
        size: 200,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'address',
        header: t('address'),
        size: 200,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        size: 200,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'second_phone',
        header: t('secondPhone'),
        size: 200,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
      {
        header: t('actions'),
        Cell: ({ row }) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <IconButton
                color="error"
                onClick={() => { props.handleDeleteDialogOpen(row.original) }}>
                <DeleteIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {props.handleUpdateDialogOpen(row.original); }}>
                <EditIcon />
              </IconButton>
          </Box>
          );
        },
        size: 150,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        muiTableFooterCellProps: {
          align: 'left',
        },
      },
    ], [t, props]);

  const table = useMaterialReactTable({
    columns,
    data: props.customers,
    state: {
      isLoading: props.isLoading,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    enableDensityToggle: false,
    enableStickyHeader: true,
    columnResizeDirection: 'rtl',
    initialState: {
      pagination: { pageSize: 40, pageIndex: 0 },
      showColumnFilters: false,
      showGlobalFilter: true,
      columnVisibility: { 'details': false, 'action_result': false, 'action_result_code': false },
      density: 'compact',
    },
    localization: i18n.language == 'ar' ? MRT_Localization_AR : MRT_Localization_HE
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
}
