import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateVehicle, UpdateVehicle} from 'types/vehicle';
import { 
    get_all_vehicle_service, 
    get_vehicle_by_id_service, 
    create_vehicle_service,
    update_vehicle_service,
    delete_vehicle_service } from 'services/vehicle'

export const getAllVehicleAction = createAsyncThunk(
    'vehicle/getAllVehicle',
    async (_, thunkAPI) => {
        try {
            return await get_all_vehicle_service();
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const getVehicleByIDAction = createAsyncThunk(
    'vehicle/getAllVehicle',
    async (id:string, thunkAPI) => {
        try {
            return await get_vehicle_by_id_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const createVehicleAction = createAsyncThunk(
    'vehicle/createVehicle',
    async (data: CreateVehicle, thunkAPI) => {
        try {
            return await create_vehicle_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);

export const updateVehicleAction = createAsyncThunk(
    'vehicle/updateVehicle',
    async (data: UpdateVehicle, thunkAPI) => {
        try {
            return await update_vehicle_service(data);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);


export const deleteVehicleAction = createAsyncThunk(
    'vehicle/deleteVehicle',
    async (id: string, thunkAPI) => {
        try {
            return await delete_vehicle_service(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response)
        }
    },
);
