import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import { 
    getAllDriverAction,
    createDriverAction,
    updateDriverAction,
    deleteDriverAction
 } from 'store/actions/driver';
import DriverTable from './driverTable';
import { CreateDriver, UpdateDriver, Driver as DriverType } from 'types/driver';
import NewDriverForm from './newDriverDialog';
import DeleteDriverDialog from './deleteDriverDialog';
import UpdateDriverDialog from './updateDriverDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IDriverProps {
}

export default function DriverView(props: IDriverProps) {
    const { t } = useTranslation();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [selectedDriver, setSelectedDriver] = React.useState<DriverType | null>(null);
    const { drivers, driversIsLoading, driversError, driverRequest } = useAppSelector(state => state.store.driver);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getAllDriverAction());
    }, [dispatch]);

    const onCreate = (data: CreateDriver) => {
        dispatch(createDriverAction(data)).then((resp)=>{
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('driverCreatedSuccessfully'));
            }
        })
    }

    const onUpdate = (data: UpdateDriver) => {
        dispatch(updateDriverAction(data)).then((resp)=>{
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenUpdateDialog(false);
                toast.success(t('driverUpdatedSuccessfully'));
            }
        })
    }

    const onDelete = (id: string) => {
        dispatch(deleteDriverAction(id)).then((resp)=>{
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenDeleteDialog(false);
                toast.success(t('driverDeletedSuccessfully'));
            }
        })
    }

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    }

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false);
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    }

    const handleUpdateDialogOpen = (driver: DriverType) => {
        setSelectedDriver(driver);
        setOpenUpdateDialog(true);
    }

    const handleDeleteDialogOpen = (driver: DriverType) => {
        setSelectedDriver(driver);
        setOpenDeleteDialog(true);
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="body1"
                        mb={1}
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            marginTop: '1rem',
                        }}
                    >
                        {t('drivers')}
                    </Typography>
                    <Button 
                        disabled={driversIsLoading || driverRequest}
                        variant='contained' 
                        color='secondary' 
                        onClick={handleCreateDialogOpen}>
                        {t('addDriver')}
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <DriverTable
                        openCreateDialog={openCreateDialog}
                        openUpdateDialog={openUpdateDialog}
                        isLoading={driversIsLoading || driverRequest}
                        drivers={drivers}
                        selectedDriver={selectedDriver}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        handleUpdateDialogClose={handleUpdateDialogClose}
                        handleDeleteDialogClose={handleDeleteDialogClose}
                        handleUpdateDialogOpen={handleUpdateDialogOpen}
                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                </Grid>
            </Grid>
            <NewDriverForm open={openCreateDialog} onCreate={onCreate} onClose={handleCreateDialogClose} requesting={driverRequest} />
            <DeleteDriverDialog selectedDriver={selectedDriver} open={openDeleteDialog} onDelete={onDelete} onClose={handleDeleteDialogClose} requesting={driverRequest} />
            <UpdateDriverDialog selectedDriver={selectedDriver} open={openUpdateDialog} onUpdate={onUpdate} onClose={handleUpdateDialogClose} requesting={driverRequest} />
        </>
    );
}
