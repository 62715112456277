import React from 'react';
import { Box, Button, TextField, Grid, CircularProgress, CardHeader, Modal, IconButton, Typography } from '@mui/material';
import { Document } from 'types/document';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface DeleteDocumentDialogProps {
    selectedDocument: Document | null;
    onDelete: (id: string) => void;
    onClose: () => void;
    requesting: boolean;
    open: boolean;
}

const modalStyles = {
    position: 'absolute' as const,
    width: { xs: '90%', sm: '60%', md: '40%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const DeleteDocumentDialog: React.FC<DeleteDocumentDialogProps> = ({ selectedDocument, onDelete, onClose, requesting, open }) => {
    const { t } = useTranslation();

    const handleDelete = () => {
        if (selectedDocument && selectedDocument.document_id) {
            onDelete(selectedDocument.document_id);
        }
    };

    return (
        <Modal open={open} onClose={onClose} sx={{ backdropFilter: 'blur(2px)' }}>
        <Box sx={modalStyles}>
            <Box sx={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
                {t('confirmDeletion')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
                {t('areYouSureDelete', { $1: selectedDocument?.document_id })}
            </Typography>
            <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    {t('cancel')}
                </Button>
                <Button onClick={handleDelete} color="error" variant="contained" disabled={requesting}>
                    {requesting ? <CircularProgress size={24} sx={{ color: 'text.primaryChannel' }} /> : t('delete')}
                </Button>
            </Box>
        </Box>
    </Modal>
    )
};

export default DeleteDocumentDialog;
