
import { UpdateDocument, CreateDocument, DocumentApiResponse } from 'types/document'
import { pagination } from 'types/pagination';
import axiosInstance from 'services/axiosInstance'


export const get_all_document_service = async () => {
  const response = await axiosInstance.get('/document/');
  return response;
};

export const get_document_pagination_service = async (data: any = {}) => {

  const { pageIndex, pageSize, columnFilters, globalFilter, sorting } = data;

  const request: { [key: string]: any } = {
    page: pageIndex + 1,
    page_size: pageSize,
  };

  if (columnFilters && columnFilters.length > 0) {
    request.filters = JSON.stringify(columnFilters);
  }

  if (globalFilter) {
    request.global_filter = globalFilter;
  }

  if (sorting && sorting.length > 0) {
    request.sorting = JSON.stringify(sorting);
  }

  const response = await axiosInstance.get('/document/', { params: request });
  return response;
};

export const download_documents_excel_service = async (data: any = {}) => {

  const { pageIndex, pageSize, columnFilters, globalFilter, sorting } = data;

  const request: { [key: string]: any } = {};

  if (columnFilters && columnFilters.length > 0) {
    request.filters = JSON.stringify(columnFilters);
  }

  if (globalFilter) {
    request.global_filter = globalFilter;
  }

  if (sorting && sorting.length > 0) {
    request.sorting = JSON.stringify(sorting);
  }
  const response = await axiosInstance.get('/documents/download/', {
    params: request,
    responseType: 'blob',
  });
  return response;
};

export const get_document_by_id_service = async (id: string) => {
  const response = await axiosInstance.get(`/document/${id}/`);
  return response;
};

export const get_document_by_uuid_service = async (uuid: string) => {
  const response = await axiosInstance.get(`/documents/${uuid}`);
  return response;
}
export const create_document_service = async (data: CreateDocument) => {
  const response = await axiosInstance.post('/document/', {
    entry_time: data.entry_time,
    exit_time: data.exit_time,
    date: data.date,
    created_by_user: data.created_by_user,
    customer_id: data.customer_id,
    driver_id: data.driver_id,
    vehicle_id: data.vehicle_id,
    trailer_id: data.trailer_id,
    material_id: data.material_id,
    go_to: data.go_to,
    measured_thing: data.measured_thing,
    weight_before: data.weight_before,
    weight_after: data.weight_after,
    comment: data.comment,
  });
  return response;
}

export const update_document_service = async (data: UpdateDocument) => {
  const response = await axiosInstance.put(`/document/${data.id}/`, {
    created_by_user: data.created_by_user,
    customer_id: data.customer_id,
    driver_id: data.driver_id,
    vehicle_id: data.driver_id,
    trailer_id: data.trailer_id,
    material_id: data.material_id,
    status: data.status,
    comment: data.comment,
    go_to: data.go_to,
    measured_thing: data.measured_thing,
    weight_before: data.weight_before,
    weight_after: data.weight_after,
    printed: data.printed,

  });
  return response;
}

export const delete_document_service = async (id: string) => {
  const response = await axiosInstance.delete(`/document/${id}/`);
  return response;
}