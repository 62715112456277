import React, { useState, useMemo, useEffect } from 'react';
import { Document } from 'types/document';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { MRT_Localization_AR } from 'material-react-table/locales/ar';
import { MRT_Localization_HE } from 'material-react-table/locales/he';
import { Button } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import i18n from 'locale/i18n';
import 'dayjs/locale/he';
import 'dayjs/locale/ar';
import { useAppSelector } from 'store/index';

type IRecentDocumentTableProps = {
  selectedDocument: Document | null;
  handleSelectDocument: (document: Document | null) => void;
  getData: (params?: any) => void;
};

const RecentDocumentTable: React.FC<IRecentDocumentTableProps> = (props) => {
  const { t } = useTranslation();
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { documentsIsLoading, documentsError, documentRequest, paginationData } = useAppSelector((state) => state.store.document);

  useEffect(() => {
    props.getData({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize, columnFilters, globalFilter, sorting });
  }, [pagination, columnFilters, globalFilter, sorting]);

  const handleResetFilters = () => {
    setColumnFilters([]);
    setGlobalFilter('');
    setSorting([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    props.getData({ pageIndex: 0, pageSize: 10, columnFilters: [], globalFilter: '', sorting: [] });
  };

  const columns = useMemo<MRT_ColumnDef<Document>[]>(
    () => [
      {
        accessorKey: 'document_id',
        header: t('ID'),
        size: 20,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'date',
        accessorFn: (originalRow) => dayjs(originalRow.date).format('DD/MM/YYYY'),
        header: t('date'),
        size: 50,
        filterVariant: 'date',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'customer.name',
        header: t('customerName'),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'driver.name',
        header: t('driverName'),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'vehicle.name',
        header: t('vehicleName'),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'vehicle.vehicle_number',
        header: t('vehicleNumber'),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'trailer.trailer_number',
        header: t('trailerNumber'),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'material.name',
        header: t('materialName'),
        size: 50,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'go_to',
        header: t('address'),
        size: 50,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'measured_thing',
        header: t('measuredThing'),
        size: 50,
        Cell: ({ cell }: any) => <span>{t(cell.getValue())}</span>,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      // {
      //   accessorKey: 'weight_before',
      //   header: t('weightBefore'),
      //   size: 50,
      //   filterVariant: 'text',
      // },
      // {
      //   accessorKey: 'weight_after',
      //   header: t('weightAfter'),
      //   size: 50,
      //   filterVariant: 'text',
      // },
      {
        accessorKey: 'status',
        header: t('status'),
        size: 50,
        Cell: ({ cell }: any) => <span>{t(cell.getValue())}</span>,
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'comment',
        header: t('comment'),
        size: 50,
        Cell: ({ cell }: any) => (
          <span
            style={{
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '10rem',
            }}
          >
            {t(cell.getValue())}
          </span>
        ),
        filterVariant: 'text',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
    ],
    [t]
  );

  const table = useMaterialReactTable({
    columns,
    data: paginationData?.results || [],
    columnResizeDirection: 'rtl',
    initialState: { 
      showColumnFilters: false,
      density: 'compact',

    },
    enableStickyHeader: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableColumnFilters: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Button onClick={() => props.getData({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize })} variant='contained' color='success' disabled={paginationData?.results.length === 0} sx={{
        fontSize: '1.1rem',
        fontWeight: 'bold',
        opacity: 0.9,
      }}>
        <RefreshIcon />
      </Button>
    ),
    rowCount: paginationData?.count || 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading: documentsIsLoading,
      pagination,
      showAlertBanner: documentsError ? true : false,
      showProgressBars: documentRequest,
      sorting,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
    }),
    localization: i18n.language === 'ar' ? MRT_Localization_AR : MRT_Localization_HE,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => props.handleSelectDocument(row.original),
      sx: {
        cursor: 'pointer',
        backgroundColor: row.original.document_id === props.selectedDocument?.document_id ? '#e0f7fa' : undefined,
      },
    }),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language === 'ar' ? 'ar' : 'he'}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

export default RecentDocumentTable;
