import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import { 
    getAllMaterialAction,
    createMaterialAction,
    updateMaterialAction,
    deleteMaterialAction
 } from 'store/actions/material';
import MaterialTable from './materialTable';
import { CreateMaterial, UpdateMaterial, Material as MaterialType } from 'types/material';
import NewMaterialForm from './newMaterialDialog';
import DeleteMaterialDialog from './deleteMaterialDialog';
import UpdateMaterialDialog from './updateMaterialDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IMaterialProps {}

export default function MaterialView(props: IMaterialProps) {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [selectedMaterial, setSelectedMaterial] = React.useState<MaterialType | null>(null);
    const { materials, materialsIsLoading, materialsError, materialRequest } = useAppSelector(state => state.store.material);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(getAllMaterialAction());
    }, [dispatch]);

    const onCreate = (data: CreateMaterial) => {
        dispatch(createMaterialAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('materialCreatedSuccessfully'));
            }
        });
    };

    const onUpdate = (data: UpdateMaterial) => {
        dispatch(updateMaterialAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenUpdateDialog(false);
                toast.success(t('materialUpdatedSuccessfully'));
            }
        });
    };

    const onDelete = (id: string) => {
        dispatch(deleteMaterialAction(id)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setOpenDeleteDialog(false);
                toast.success(t('materialDeletedSuccessfully'));
            }
        });
    };

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    };

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    };

    const handleUpdateDialogOpen = (material: MaterialType) => {
        setSelectedMaterial(material);
        setOpenUpdateDialog(true);
    };

    const handleDeleteDialogOpen = (material: MaterialType) => {
        setSelectedMaterial(material);
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        variant="body1"
                        mb={1}
                        sx={{ color: 'text.primary', fontWeight: 'bold', marginTop: '1rem' }}
                    >
                        {t('materials')}
                    </Typography>
                    <Button 
                        disabled={materialRequest||materialsIsLoading}
                        variant='contained' 
                        color='secondary' 
                        onClick={handleCreateDialogOpen}>
                        {t('addMaterial')}
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <MaterialTable
                        openCreateDialog={openCreateDialog}
                        openUpdateDialog={openUpdateDialog}
                        isLoading={materialsIsLoading || materialRequest}
                        materials={materials}
                        selectedMaterial={selectedMaterial}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        handleUpdateDialogClose={handleUpdateDialogClose}
                        handleDeleteDialogClose={handleDeleteDialogClose}
                        handleUpdateDialogOpen={handleUpdateDialogOpen}
                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                </Grid>
            </Grid>
            <NewMaterialForm 
                open={openCreateDialog} 
                onCreate={onCreate} 
                onClose={handleCreateDialogClose} 
                requesting={materialRequest} 
            />
            <DeleteMaterialDialog 
                selectedMaterial={selectedMaterial} 
                open={openDeleteDialog} 
                onDelete={onDelete} 
                onClose={handleDeleteDialogClose} 
                requesting={materialRequest} 
            />
            <UpdateMaterialDialog 
                selectedMaterial={selectedMaterial} 
                open={openUpdateDialog} 
                onUpdate={onUpdate} 
                onClose={handleUpdateDialogClose} 
                requesting={materialRequest} 
            />
        </>
    );
}
