import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authStateType } from 'types/auth';
import { loginAction, logoutAction, checkAuthAction } from 'store/actions/auth';
import { setUser, removeUser, getUser } from 'utils/authUtils';


const initialState: authStateType = {
  token: null,
  isAuthenticated: false,
  loading: false,
  user: null,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkLocalStorageForUser: (state) => {
      const userExist = getUser()

      if (userExist) {
        const user = userExist;
        state.isAuthenticated = true;
        state.user = user;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // *********** LOGIN START *********** \\
      .addCase(loginAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAction.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 200) {
          state.isAuthenticated = true;
          state.user = action.payload.data;
          setUser(action.payload.data);
        }
        state.loading = false;
      })

      .addCase(loginAction.rejected, (state, action: PayloadAction<any>) => {
        if (action.payload.request.status === 401) {
          state.isAuthenticated = false;
          state.user = null;
          removeUser();
          state.error = 'invalidCredentials';
        } else {
          state.error = 'somethingWentWrong';
        }
        state.loading = false;
      })
      // *********** LOGIN END *********** \\
      
      // *********** LOGOUT START *********** \\
        .addCase(logoutAction.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(logoutAction.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.user = null;
            removeUser();
            state.loading = false;
        })
        .addCase(logoutAction.rejected, (state) => {
            state.error = 'somethingWentWrong';
            state.loading = false;
        })
      // *********** LOGOUT END *********** \\

      // *********** LOGOUT START *********** \\
        .addCase(checkAuthAction.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(checkAuthAction.fulfilled, (state) => {
            state.loading = false;
            state.error = null; 
        })
        .addCase(checkAuthAction.rejected, (state, action: PayloadAction<any>) => {
          if (action.payload.request.status === 401) {
              state.isAuthenticated = false;
              state.user = null;
              removeUser();
              state.loading = false;
          }
        });
      // *********** LOGOUT END *********** \\

  },
  
});
export const { checkLocalStorageForUser } = authSlice.actions

export default authSlice.reducer;