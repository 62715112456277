import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import { 
    getAllCustomerAction,
    createCustomerAction,
    updateCustomerAction,
    deleteCustomerAction
} from 'store/actions/customer';
import CustomerTable from './customerTable';
import { CreateCustomer, UpdateCustomer, Customer as CustomerType } from 'types/customer';
import NewCustomerForm from './newCustomerDialog';
import DeleteCustomerDialog from './deleteCustomerDialog';
import UpdateCustomerDialog from './updateCustomerDialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface ICustomerProps {}

export default function CustomerView(props: ICustomerProps) {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerType | null>(null);
    const { customers, customersIsLoading, customersError, customerRequest } = useAppSelector(state => state.store.customer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(getAllCustomerAction());
    }, [dispatch]);

    const onCreate = (data: CreateCustomer) => {
        dispatch(createCustomerAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('customerCreatedSuccessfully'));
            }
        });
    };

    const onUpdate = (data: UpdateCustomer) => {
        dispatch(updateCustomerAction(data)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('customerUpdatedSuccessfully'));
                setOpenUpdateDialog(false);
            }
        });
    };

    const onDelete = (id: string) => {
        dispatch(deleteCustomerAction(id)).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                toast.success(t('customerDeletedSuccessfully'));
                setOpenDeleteDialog(false);
            }
        });
    };

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    };

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false);
    };

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    };

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    };

    const handleUpdateDialogOpen = (customer: CustomerType) => {
        setSelectedCustomer(customer);
        setOpenUpdateDialog(true);
    };

    const handleDeleteDialogOpen = (customer: CustomerType) => {
        setSelectedCustomer(customer);
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="body1"
                        mb={1}
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            marginTop: '1rem',
                        }}
                    >
                        {t('customers')}
                    </Typography>
                    <Button 
                        disabled={customerRequest||customersIsLoading}
                        variant='contained' 
                        color='secondary' 
                        onClick={handleCreateDialogOpen}>
                        {t('addCustomer')}
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <CustomerTable
                        openCreateDialog={openCreateDialog}
                        openUpdateDialog={openUpdateDialog}
                        isLoading={customersIsLoading || customerRequest}
                        customers={customers}
                        selectedCustomer={selectedCustomer}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        handleUpdateDialogClose={handleUpdateDialogClose}
                        handleDeleteDialogClose={handleDeleteDialogClose}
                        handleUpdateDialogOpen={handleUpdateDialogOpen}
                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                </Grid>
            </Grid>
            <NewCustomerForm open={openCreateDialog} onCreate={onCreate} onClose={handleCreateDialogClose} requesting={customerRequest} />
            <DeleteCustomerDialog selectedCustomer={selectedCustomer} open={openDeleteDialog} onDelete={onDelete} onClose={handleDeleteDialogClose} requesting={customerRequest} />
            <UpdateCustomerDialog selectedCustomer={selectedCustomer} open={openUpdateDialog} onUpdate={onUpdate} onClose={handleUpdateDialogClose} requesting={customerRequest} />
        </>
    );
}
