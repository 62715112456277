import React from 'react';
import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { updateLanguage } from 'utils/language';
import UserAvatar from 'components/user/userAvater';
import { useTranslation } from 'react-i18next';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface HeaderProps {
  open: boolean;
  toggleDrawer: () => void;
  drawerWidth?: number;
}


const Header: React.FC<HeaderProps> = ({ open, toggleDrawer, drawerWidth = 300 }) => {
  const {t}=useTranslation()

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (

    <AppBar position="absolute" open={open}>
      <Toolbar

        sx={{
          pr: '24px',
        }}
      >
        <IconButton
          edge="start"

          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon style={{color:'#fff'}}/>
        </IconButton>
        
        <Typography
          component="a"
          variant="h6"
          href='/'
          noWrap
          sx={{
            flexGrow: 1,
            marginLeft: '20px',
            fontWeight: 'bold',
            fontSize: '2rem',
            background: '#fff',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundSize: '200% 200%',
          }}
        >
          מערכת שקילה ב.א.י.ו. בע"מ
        </Typography>

        <IconButton>
          <UserAvatar />
        </IconButton>
      </Toolbar>
    </AppBar>

  );
};

export default Header;