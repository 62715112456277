
import { combineReducers } from 'redux';
import { authSlice } from './authReducer';
import { driverSlice } from './driverReducer';
import { customerSlice } from './customerReducer';
import { materialSlice } from './materialReducer';
import { vehicleSlice } from './vehicleReducer';
import { DocumentSlice } from './documentReducer';
import { trailerSlice } from './trailerReducer';
export const rootReducer = combineReducers({
    auth: authSlice.reducer,
    driver: driverSlice.reducer,
    customer: customerSlice.reducer,
    material: materialSlice.reducer,
    vehicle: vehicleSlice.reducer,
    document: DocumentSlice.reducer,
    trailer: trailerSlice.reducer
});