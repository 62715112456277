import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MaterialState } from 'types/material';
import { 
    getAllMaterialAction, 
    getMaterialByIDAction, 
    createMaterialAction,
    updateMaterialAction,
    deleteMaterialAction
} from 'store/actions/material';


const initialState: MaterialState = {
    materials: [],
    materialsIsLoading: false,
    materialsError: '',
    materialRequest: false
};

export const materialSlice = createSlice({
  name: 'Material',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // *********** GET ALL Material START *********** \\
    .addCase(getAllMaterialAction.pending, (state) => {
        state.materialsIsLoading = true;
        state.materialsError = '';
    })
    .addCase(getAllMaterialAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.materials = action.payload.data;
        state.materialsIsLoading = false;
    })
    .addCase(getAllMaterialAction.rejected, (state, action: PayloadAction<any>) => {
        state.materialsError = action.payload.message;
        state.materialsIsLoading = false;
    })
    // *********** GET ALL Material END *********** \\
    // *********** CREATE Material START *********** \\
    .addCase(createMaterialAction.pending, (state) => {
        state.materialsIsLoading = true;
        state.materialsError = '';
    })
    .addCase(createMaterialAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.materials.push(action.payload.data);
        state.materialsIsLoading = false;
    })
    .addCase(createMaterialAction.rejected, (state, action: PayloadAction<any>) => {
        state.materialsError = action.payload.message;
        state.materialsIsLoading = false;
    })
    // *********** CREATE Material END *********** \\
    // *********** UPDATE Material START *********** \\
    .addCase(updateMaterialAction.pending, (state) => {
        state.materialsIsLoading = true;
        state.materialsError = '';
    })
    .addCase(updateMaterialAction.fulfilled, (state, action: PayloadAction<any>) => {
        const index = state.materials.findIndex((material) => material.material_id === action.payload.data.material_id);
        state.materials[index] = action.payload.data;
        state.materialsIsLoading = false;
    })
    .addCase(updateMaterialAction.rejected, (state, action: PayloadAction<any>) => {
        state.materialsError = action.payload.message;
        state.materialsIsLoading = false;
    })
    // *********** UPDATE Material END *********** \\
    // *********** DELETE Material START *********** \\
    .addCase(deleteMaterialAction.pending, (state) => {
        state.materialsIsLoading = true;
        state.materialsError = '';
    })
    .addCase(deleteMaterialAction.fulfilled, (state, action: PayloadAction<any>) => {
        state.materials = state.materials.filter((Material) => Material.material_id !== action.payload.data.material_id);
        state.materialsIsLoading = false;
    })
    .addCase(deleteMaterialAction.rejected, (state, action: PayloadAction<any>) => {
        state.materialsError = action.payload.message;
        state.materialsIsLoading = false;
    })
    // *********** DELETE Material END *********** \\
  },
  
});
export const { } = materialSlice.actions

export default materialSlice.reducer;