import { Box, Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'store/index';
import DocumentTable from './documentTable';
import { CreateDocument, UpdateDocument, Document as DocumentType } from 'types/document';
import NewDocumentDialog from './newDocumentDialog';
import DeleteDocumentDialog from './deleteDocumentDialog';
import UpdateDocumentDialog from './updateDocumentDialog';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    getAllDocumentAction,
    getDocumentPaginationAction,
    createDocumentAction,
    updateDocumentAction,
    deleteDocumentAction,
} from 'store/actions/document';
import { getAllCustomerAction } from 'store/actions/customer';
import { getAllDriverAction } from 'store/actions/driver';
import { getAllVehicleAction } from 'store/actions/vehicle';
import { getAllTrailerAction } from 'store/actions/trailer';
import { getAllMaterialAction } from 'store/actions/material';
import FormLoadingSkeleton from './loadingForm';

export interface IDocumentProps {
}

export default function DocumentView(props: IDocumentProps) {
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = React.useState<DocumentType | null>(null);
    const { documents, documentsIsLoading, documentsError, documentRequest } = useAppSelector(state => state.store.document);
    const { driversIsLoading } = useAppSelector((state) => state.store.driver);
    const { vehiclesIsLoading } = useAppSelector((state) => state.store.vehicle);
    const { materialsIsLoading } = useAppSelector((state) => state.store.material);
    const { customersIsLoading } = useAppSelector((state) => state.store.customer);
    const { trailersIsLoading } = useAppSelector((state) => state.store.trailer);


    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getData = (parms?: any) => {
        dispatch(getDocumentPaginationAction(parms))
    }

    React.useEffect(() => {
        dispatch(getAllCustomerAction())
        dispatch(getAllDriverAction())
        dispatch(getAllVehicleAction())
        dispatch(getAllTrailerAction())
        dispatch(getAllMaterialAction())
    }, [dispatch]);

    const onCreate = (data: CreateDocument) => {
        dispatch(createDocumentAction(data)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setSelectedDocument(resp.payload.data as DocumentType)
                toast.success(t('documentCreatedSuccessfully'));
            }
        });
    }

    const onUpdate = (data: UpdateDocument, close:boolean = false) => {
        dispatch(updateDocumentAction(data)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                if (close) {
                    setSelectedDocument(null)
                    setOpenUpdateDialog(false);
                }else{
                    setSelectedDocument(resp.payload.data as DocumentType)
                }
                toast.success(t('documentUpdatedSuccessfully'));
            }
        });
    }

    const onDelete = (id: string) => {
        dispatch(deleteDocumentAction(id)).then((resp: any) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setSelectedDocument(null)
                setOpenDeleteDialog(false);
                setOpenUpdateDialog(false);
                toast.success('Document deleted successfully');
            }
        })
    }

    const handleCreateDialogClose = () => {
        setOpenCreateDialog(false);
    }

    const handleUpdateDialogClose = () => {
        setSelectedDocument(null);
        setOpenUpdateDialog(false);
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const handlePreviewDialogClose = () => {
        setOpenPreviewDialog(false);
    }

    const handleCreateDialogOpen = () => {
        setOpenCreateDialog(true);
    }

    const handleUpdateDialogOpen = (Document: DocumentType) => {
        setSelectedDocument(Document);
        setOpenUpdateDialog(true);
    }

    const handleDeleteDialogOpen = (Document: DocumentType) => {
        setSelectedDocument(Document);
        setOpenDeleteDialog(true);
    }

    const handlePreviewDialogOpen = (Document: DocumentType) => {
        setSelectedDocument(Document);
        setOpenPreviewDialog(true);
    }

    return (
        <>
            {
                driversIsLoading || vehiclesIsLoading || materialsIsLoading || customersIsLoading || trailersIsLoading ?
                    <FormLoadingSkeleton />
                    :

                    <>
                        <Stack spacing={2} justifyContent="center" alignItems="center" width="100%">
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" width="80%">
                                <Typography
                                    variant="body1"
                                    mb={1}
                                    sx={{
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                        marginTop: '1rem',
                                    }}
                                >
                                    {t('documents')}
                                </Typography>
                                <Button variant='contained' color='secondary' onClick={handleCreateDialogOpen}>
                                    {t('addDocument')}
                                </Button>
                            </Stack>
                            <Box sx={{
                                width: '80%'
                            }}>
                                <DocumentTable
                                    getData={getData}
                                    openCreateDialog={openCreateDialog}
                                    openUpdateDialog={openUpdateDialog}
                                    openPreviewDialog={openPreviewDialog}
                                    isLoading={documentsIsLoading || documentRequest}
                                    documents={documents}
                                    selectedDocument={selectedDocument}
                                    onUpdate={onUpdate}
                                    onDelete={onDelete}
                                    handleUpdateDialogClose={handleUpdateDialogClose}
                                    handleDeleteDialogClose={handleDeleteDialogClose}
                                    handlePreviewDialogClose={handlePreviewDialogClose}
                                    handleUpdateDialogOpen={handleUpdateDialogOpen}
                                    handleDeleteDialogOpen={handleDeleteDialogOpen}
                                    handleOpenPreviewDialog={handlePreviewDialogOpen}
                                />
                            </Box>
                        </Stack>
                        <NewDocumentDialog open={openCreateDialog} onCreate={onCreate} onClose={handleCreateDialogClose} requesting={documentRequest} />
                        <DeleteDocumentDialog selectedDocument={selectedDocument} open={openDeleteDialog} onDelete={onDelete} onClose={handleDeleteDialogClose} requesting={documentRequest} />
                        <UpdateDocumentDialog selectedDocument={selectedDocument} open={openUpdateDialog} onUpdate={onUpdate} onDelete={onDelete} onClose={handleUpdateDialogClose} requesting={documentRequest} />
                    </>
            }
        </>

    );
}
